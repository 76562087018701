import React, { useState, useEffect, useMemo } from "react";
import { FormBox } from "../doctor/style";
import * as Yup from 'yup';
import { Button } from 'src/components/shared';
import { Grid, Typography } from "@mui/material";
import { addTokenAppointmentRequest, updateTokenAppointmentRequest, fetchTokenAppointmentByIdRequest, fetchDoctornameRequest, fetchPatientnameRequest, fetchClinicnameRequest } from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import AppTimePicker from "src/components/shared/Form/Timepicker";
import InputComponent from "src/components/shared/Form/Input";
import Select from "src/components/shared/Form/Select";
import Datepicker from "src/components/shared/Form/Datepicker";
import dayjs from 'dayjs';
import { useParams, useNavigate } from 'react-router-dom';
import toast from "src/utils/toast";

function AddEditTokenAppointment() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors || []);
    const clinics = useSelector((state) => state.app.clinics || []);
    const patients = useSelector((state) => state.app.patients || []);
    const { id } = useParams();  
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        date: dayjs().format('YYYY-MM-DD'),
        time: null,
        addnotes: '',
        scheduleType: '',
        user: '',
        patient: '',
        clinic: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchDoctornameRequest(resolve, reject))),
                    new Promise((resolve, reject) => dispatch(fetchClinicnameRequest(resolve, reject))),
                    new Promise((resolve, reject) => dispatch(fetchPatientnameRequest(resolve, reject))),
                ]);

                if (id) {  
                    await new Promise((resolve, reject) => dispatch(fetchTokenAppointmentByIdRequest(id, resolve, reject)))
                        .then((appointment) => {
                            setInitialValues({
                                date: appointment.date || dayjs().format('YYYY-MM-DD'),
                                time: appointment.time || null,
                                addnotes: appointment.addnotes || '',
                                scheduleType: appointment.scheduleType || '',
                                user: appointment.user || '',
                                patient: appointment.patient || '',
                                clinic: appointment.clinic || '',
                            });
                        });
                }
                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    const handleSubmit = async (values, { resetForm }) => {
        const tokenAppointment = {
            ...values,
            time: values.time ? dayjs(values.time, 'HH:mm').toISOString() : null
        };

        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updateTokenAppointmentRequest(id, tokenAppointment, resolve, reject));
                });
                toast.success('Appointment Updated Successfully');
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addTokenAppointmentRequest(tokenAppointment, resolve, reject));
                });
                toast.success('Appointment Added Successfully');
            }

            resetForm();
            navigate('/appointments');  
        } catch (error) {
            console.log(error);
        }
    };

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({
            label: doctor.name,
            value: doctor.id,
        }))
    ), [doctors]);

    const clinicOptions = useMemo(() => (
        clinics.map((clinic) => ({
            label: clinic.name,
            value: clinic.id,
        }))
    ), [clinics]);

    const patientOptions = useMemo(() => (
        patients.map((patient) => ({
            label: patient.name,
            value: patient.id,
        }))
    ), [patients]);

    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    date: Yup.string().required('Please select the date!'),
                    time: Yup.string().required('Please select the time!'),
                    addnotes: Yup.string().required('Please enter the note!'),
                    scheduleType: Yup.string().required('Please select the scheduleType!'),
                    user: Yup.string().required('Please select the user!'),
                    clinic: Yup.string().required('Please select the clinic!'),
                    patient: Yup.string().required('Please select the patient!'),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Typography variant="h4" color='#393A96' fontWeight='bold'>
                                {id ? 'Edit Token Appointment' : 'Add Token Appointment'}
                            </Typography>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Pick Date
                                </Typography>
                                <Datepicker
                                    variant="outlined"
                                    fullWidth
                                    type="date"
                                    name="date"
                                    value={values.date}
                                    onChange={(value) => setFieldValue('date', dayjs(value).format('YYYY-MM-DD'))}
                                    error={touched.date && Boolean(errors.date)}
                                    helperText={touched.date && errors.date}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Pick Time
                                </Typography>
                                <AppTimePicker
                                    variant="outlined"
                                    fullWidth
                                    value={values.time}
                                    onChange={(value) => setFieldValue('time', value)}
                                    renderInput={(params) => (
                                        <InputComponent
                                            {...params}
                                            error={touched.time && Boolean(errors.time)}
                                            helperText={touched.time && errors.time}
                                        />
                                    )}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Add Notes
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="addnotes"
                                    value={values.addnotes}
                                    onChange={handleChange}
                                    error={touched.addnotes && Boolean(errors.addnotes)}
                                    helperText={touched.addnotes && errors.addnotes}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Type
                                </Typography>
                                <Select
                                    variant="outlined"
                                    fullWidth
                                    name="scheduleType"
                                    value={values.scheduleType}
                                    onChange={(e) => setFieldValue('scheduleType', e.target.value)}
                                    options={[
                                        { label: 'Token', value: 'token' },
                                        { label: 'Appointment', value: 'appointment' }
                                    ]}
                                    error={touched.scheduleType && Boolean(errors.scheduleType)}
                                    helperText={touched.scheduleType && errors.scheduleType}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='user'
                                    value={values.user}
                                    onChange={(e) => setFieldValue('user', e.target.value)}
                                    options={doctorOptions}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Clinic
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='clinic'
                                    value={values.clinic}
                                    onChange={(e) => setFieldValue('clinic', e.target.value)}
                                    options={clinicOptions}
                                    placeholder="Select a clinic"
                                    error={touched.clinic && Boolean(errors.clinic)}
                                    helperText={touched.clinic && errors.clinic}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Patient
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='patient'
                                    value={values.patient}
                                    onChange={(e) => setFieldValue('patient', e.target.value)}
                                    options={patientOptions}
                                    placeholder="Select a patient"
                                    error={touched.patient && Boolean(errors.patient)}
                                    helperText={touched.patient && errors.patient}
                                />
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    fullWidth
                                    color="primary"
                                    size="large"
                                    disabled={isLoading}
                                >
                                    {id ? 'Update Token Appointment' : 'Add Token Appointment'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </FormBox>
    );
}

export default AddEditTokenAppointment;
