import { all, fork, put, call, select, takeLatest } from 'redux-saga/effects';
import * as appTypes from './appTypes';
import * as appActions from './appActions';
import { fetchDoctors, fetchPatients, deleteDoctor, deletePatient, fetchTags, fetchStaffs, fetchTasks, fetchClinics, fetchTreatments, fetchPermissions,fetchTemplates,deleteArea, deleteTreatment, deleteStaff, deleteTokenAppointment, deleteTag, deleteClinic, deleteBilling,deletePermission,deleteTemplate, deleteMessage,fetchTokenAppointments, addDoctor, addClinic,addTemplate,addTask,addStaff,addPatient,addArea,addTag,addBilling,addTokenAppointment,addPermission,addTreatment,addMessage, fetchBillings, fetchAreas,fetchTokenAppointmentsByPatient,fetchMedicalRecords,deleteMedicalRecord,addMedicalRecord,fetchFollowups,addFollowup,deleteFollowup,fetchNotificationSettings,deleteNotificationSetting, addNotificationSetting,fetchMessages,fetchNotifications,deleteNotification,addNotification,updateDoctorById,updatePatientById,updateAreaById,updatePermissionById,updateClinicById,updateStaffById,updateTagById,updateMedicalRecordById,updateTemplateById,updateNotificationById,updateTokenAppointmentById,updateBillingById,updateNotificationSettingById,updateFollowupById,updateMessageById,fetchPatientById,fetchDoctorById,fetchAreaById,fetchClinicById,fetchStaffById,fetchTagById,fetchPermissionById,fetchMedicalRecordById,fetchTemplateById,fetchNotificationById,fetchTokenAppointmentById,fetchBillingById,fetchMessageById,fetchFollowupById,fetchNotificationSettingById} from '../api/appApi';
import storage from 'src/utils/storageUtils';

function* handleAddDoctor(action) {
  try {
    const { data, resolve, reject } = action.payload?.data || {};

    const updatedData = {
      ...data,
    };
    const newDoctor = yield call(addDoctor, updatedData);
    yield put(appActions.addDoctorSuccess(newDoctor.doctor));
    if (resolve) resolve(newDoctor);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addDoctorFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddPatient(action) {
  try {
    const { data, resolve, reject } = action.payload;

    const updatedData = {
      ...data,
    };
    const newPatient = yield call(addPatient, updatedData);
    yield put(appActions.addPatientSuccess(newPatient.patient));
    if (resolve) resolve(newPatient);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addPatientFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddClinic(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
    };
    const newClinic = yield call(addClinic, updatedData);
    yield put(appActions.addClinicSuccess(newClinic.clinic));
    if (resolve) resolve(newClinic);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addClinicFailure(error));
    if (reject) reject(error);
  }
}

//add tokenappointment
function* handleAddTokenAppointment(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
    };
    const newTokenAppointment = yield call(addTokenAppointment, updatedData);
    yield put(appActions.addTokenAppointmentSuccess(newTokenAppointment.tokenAppointment));
    if (resolve) resolve(newTokenAppointment);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addTokenAppointmentFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddTask(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
    };
    const newTask = yield call(addTask, updatedData);
    yield put(appActions.addTaskSuccess(newTask.task));
    if (resolve) resolve(newTask);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addTaskFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddStaff(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
      // user: getUserIdFromToken(),
    };
    const newStaff = yield call(addStaff, updatedData);
    yield put(appActions.addStaffSuccess(newStaff.staff));
    if (resolve) resolve(newStaff);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addStaffFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddArea(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
    };
    const newArea = yield call(addArea, updatedData);
    yield put(appActions.addAreaSuccess(newArea.area));
    if (resolve) resolve(newArea);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addAreaFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddTag(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
    };
    const newTag = yield call(addTag, updatedData);
    yield put(appActions.addTagSuccess(newTag.tag));
    if (resolve) resolve(newTag);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addTagFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddBilling(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
    };
    const newBilling = yield call(addBilling, updatedData);
    yield put(appActions.addBillingSuccess(newBilling.billing));
    if (resolve) resolve(newBilling);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addBillingFailure(error));
    if (reject) reject(error);
  }
}

//add permission
function* handleAddPermission(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
    };
    const newPermission = yield call(addPermission, updatedData);
    yield put(appActions.addPermissionSuccess(newPermission.permission));
    if (resolve) resolve(newPermission);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addPermissionFailure(error));
    if (reject) reject(error);
  }
}

//add template
function* handleAddTemplate(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
    };
    const newTemplate = yield call(addTemplate, updatedData);
    yield put(appActions.addTemplateSuccess(newTemplate.template));
    if (resolve) resolve(newTemplate);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addTemplateFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddTreatment(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
    };
    const newTreatment = yield call(addTreatment, updatedData);
    yield put(appActions.addTreatmentSuccess(newTreatment.treatment));
    if (resolve) resolve(newTreatment);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addTreatmentFailure(error));
    if (reject) reject(error);
  }
}

function* handleAddMedicalRecord(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
      ...data,
    };
    const newMedicalRecord = yield call(addMedicalRecord, updatedData);
    yield put(appActions.addMedicalRecordSuccess(newMedicalRecord.medicalrecord));
    if (resolve) resolve(newMedicalRecord);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addMedicalRecordFailure(error));
    if (reject) reject(error);
  }
}

//add followup
function* handleAddFollowup(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
     ...data,
    };
    const newFollowup = yield call(addFollowup, updatedData);
    yield put(appActions.addFollowupSuccess(newFollowup.followup));
    if (resolve) resolve(newFollowup);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addFollowupFailure(error));
    if (reject) reject(error);
  }
}

//add notificationsetting
function* handleaddNotificationSetting(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
     ...data,
    };
    const newNotificationSetting = yield call(addNotificationSetting, updatedData);
    yield put(appActions.addNotificationSettingSuccess(newNotificationSetting.followup));
    if (resolve) resolve(newNotificationSetting);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addNotificationSettingFailure(error));
    if (reject) reject(error);
  }
}

//add notification
function* handleAddNotification(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
     ...data,
    };
    const newNotification = yield call(addNotification, updatedData);
    yield put(appActions.addNotificationSuccess(newNotification.notification));
    if (resolve) resolve(newNotification);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addNotificationFailure(error));
    if (reject) reject(error);
  }
}

//add message
function* handleAddMessage(action) {
  try {
    const { data, resolve, reject } = action.payload;
    const updatedData = {
     ...data,
    };
    const newMessage = yield call(addMessage, updatedData);
    yield put(appActions.addMessageSuccess(newMessage.message));
    if (resolve) resolve(newMessage);
  } catch (error) {
    const { reject } = action.payload;
    yield put(appActions.addMessageFailure(error));
    if (reject) reject(error);
  }
}

function* handleFetchDoctorname(action) {
  const { resolve = () => { }, reject = () => { } } = action.payload || {};
  try {
    const fetchedToken = storage.get('TOKEN');

    const response = yield call(fetchDoctors, fetchedToken);

    if (response && response.data && Array.isArray(response.data)) {
      const doctors = response.data.map((doctor) => ({
        name: doctor.fullName,
        id: doctor.id,
      }));
      yield put(appActions.fetchDoctornameSuccess(doctors));

      if (resolve) resolve(doctors);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchDoctornameFailure({ message: error.message }));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error fetching doctorname:', error);
    yield put(appActions.fetchDoctornameFailure({ message: error.message }));
    if (reject) reject(error);
  }
}

function* handleFetchClinicname(action) {
  const { resolve = () => { }, reject = () => { } } = action.payload || {};
  try {
    const fetchedToken = storage.get('TOKEN');

    const response = yield call(fetchClinics, fetchedToken);

    if (response && response.data && Array.isArray(response.data)) {
      const clinics = response.data.map((clinic) => ({
        name: clinic.clinicName,
        id: clinic.id,
      }));
      yield put(appActions.fetchClinicnameSuccess(clinics));

      if (resolve) resolve(clinics);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchClinicnameFailure({ message: error.message }));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error fetching clinicname:', error);
    yield put(appActions.fetchClinicnameFailure({ message: error.message }));
    if (reject) reject(error);
  }
}

function* handleFetchPatientname(action) {
  const { resolve = () => { }, reject = () => { } } = action.payload || {};
  try {
    const fetchedToken = storage.get('TOKEN');

    const response = yield call(fetchPatients, fetchedToken);

    if (response && response.data && Array.isArray(response.data)) {
      const patients = response.data.map((patient) => ({
        name: patient.fullName,
        id: patient.id,
      }));
      yield put(appActions.fetchPatientnameSuccess(patients));

      if (resolve) resolve(patients);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchPatientnameFailure({ message: error.message }));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error fetching patientname:', error);
    yield put(appActions.fetchPatientnameFailure({ message: error.message }));
    if (reject) reject(error);
  }
}


function* handleFetchDoctors(action) {
  const { resolve, reject } = action.payload;

  try {
    const response = yield call(fetchDoctors);
    if (response && response.data) {
      yield put(appActions.fetchDoctorsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {                                       
      const error = new Error('No data found');
      yield put(appActions.fetchDoctorsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchDoctorsFailure(error));
    if (reject) reject(error);
  }
}


// fetch Patient
function* handleFetchPatients(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchPatients, token);
    if (response && response.data) {
      yield put(appActions.fetchPatientsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchPatientsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchPatientsFailure(error));
    if (reject) reject(error);
  }
}

// Fetch Staff
function* handleFetchStaffs(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchStaffs, token);
    if (response && response.data) {
      yield put(appActions.fetchStaffsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchStaffsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchStaffsFailure(error));
    if (reject) reject(error);
  }
}

// fetch Task
function* handleFetchTasks(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchTasks, token);
    if (response && response.data) {
      yield put(appActions.fetchTasksSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchTasksFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchTasksFailure(error));
    if (reject) reject(error);
  }
}

function* handleFetchClinics(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchClinics, token);
    if (response && response.data) {
      yield put(appActions.fetchClinicsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchClinicsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchClinicsFailure(error));
    if (reject) reject(error);
  }
}

function* handleFetchTokenAppointment(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchTokenAppointments, token);

    if (response && response.data) {
      yield put(appActions.fetchTokenAppointmentSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchTokenAppointmentFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error fetching token appointments:', error);
    yield put(appActions.fetchTokenAppointmentFailure(error));
    if (reject) reject(error);
  }
}

function* handleFetchBillings(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchBillings, token);
    if (response && response.data) {
      yield put(appActions.fetchBillingsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchBillingsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchBillingsFailure(error));
    if (reject) reject(error);
  }
}

function* handleFetchAreas(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchAreas, token);
    if (response && response.data) {
      yield put(appActions.fetchAreasSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchAreasFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchAreasFailure(error));
    if (reject) reject(error);
  }
}

function* handleFetchTags(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchTags, token);
    if (response && response.data) {
      yield put(appActions.fetchTagsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchTagsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchTagsFailure(error));
    if (reject) reject(error);
  }
}

function* handleFetchTreatments(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchTreatments, token);
    if (response && response.data) {
      yield put(appActions.fetchTreatmentsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchTreatmentsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchTreatmentsFailure(error));
    if (reject) reject(error);
  }
}

// get permissions
function* handleFetchPermissions(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchPermissions, token);
    if (response && response.data) {
      yield put(appActions.fetchPermissionsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchPermissionsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchPermissionsFailure(error));
    if (reject) reject(error);
  }
}

//get templates
function* handleFetchTemplates(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchTemplates, token);
    if (response && response.data) {
      yield put(appActions.fetchTemplatesSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchTemplatesFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchTemplatesFailure(error));
    if (reject) reject(error);
  }
}

//get medicalrecords
function* handleFetchMedicalRecords(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchMedicalRecords, token);
    if (response && response.data) {
      yield put(appActions.fetchMedicalRecordsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchMedicalRecordsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchMedicalRecordsFailure(error));
    if (reject) reject(error);
  }
}

//fetch followups
function* handleFetchFollowups(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchFollowups, token);
    if (response && response.data) {
      yield put(appActions.fetchFollowupsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchFollowupsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchFollowupsFailure(error));
    if (reject) reject(error);
  }
}

//fetch notificationSettings
function* handleFetchNotificationSettings(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchNotificationSettings, token);
    if (response && response.data) {
      yield put(appActions.fetchNotificationSettingSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchNotificationSettingFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchNotificationSettingFailure(error));
    if (reject) reject(error);
  }
}

//get messages
function* handleFetchMessages(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchMessages, token);
    if (response && response.data) {
      yield put(appActions.fetchMessagesSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchMessagesFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchMessagesFailure(error));
    if (reject) reject(error);
  }
}

//get notifications
function* handleFetchNotifications(action) {
  const { token, resolve, reject } = action.payload;

  try {
    const token = storage.get('TOKEN');
    const response = yield call(fetchNotifications, token);
    if (response && response.data) {
      yield put(appActions.fetchNotificationsSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchNotificationsFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchNotificationsFailure(error));
    if (reject) reject(error);
  }
}

//delete 

//delete doctor
function* handleDeleteDoctor(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteDoctor, id);

    if (response) {
      yield put(appActions.deleteDoctorSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting doctor');
      yield put(appActions.deleteDoctorFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting doctor:', error);
    yield put(appActions.deleteDoctorFailure(error));
    if (reject) reject(error);
  }
}


function* handleDeletePatient(action) {

  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deletePatient, id);
    if (response) {
      yield put(appActions.deletePatientSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting Patient');
      yield put(appActions.deletePatientFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting Patient:', error);
    yield put(appActions.deletePatientFailure(error));
    if (reject) reject(error);
  }
}

function* handleDeleteTokenAppointment(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteTokenAppointment, id);

    if (response) {
      yield put(appActions.deleteTokenAppointmentSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting Token Appointment');
      yield put(appActions.deleteTokenAppointmentFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting Token Appointment:', error);
    yield put(appActions.deleteTokenAppointmentFailure(error));
    if (reject) reject(error);
  }
}

function* handleDeleteStaff(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteStaff, id);
    if (response) {
      yield put(appActions.deleteStaffSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting staff');
      yield put(appActions.deleteStaffFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting staff:', error);
    yield put(appActions.deleteStaffFailure(error));
    if (reject) reject(error);
  }
}

function* handleDeleteArea(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteArea, id);

    if (response) {
      yield put(appActions.deleteAreaSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting Area');
      yield put(appActions.deleteAreaFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting Area:', error);
    yield put(appActions.deleteAreaFailure(error));
    if (reject) reject(error);
  }
}

function* handleDeleteBilling(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteBilling, id);

    if (response) {
      yield put(appActions.deleteBillingSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting billing');
      yield put(appActions.deleteBillingFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting billing:', error);
    yield put(appActions.deleteBillingFailure(error));
    if (reject) reject(error);
  }
}

//delete clinic
function* handleDeleteClinic(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteClinic, id);

    if (response) {
      yield put(appActions.deleteClinicSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting clinic');
      yield put(appActions.deleteClinicFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting clinic:', error);
    yield put(appActions.deleteClinicFailure(error));
    if (reject) reject(error);
  }
}

//delete tag
function* handleDeleteTag(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteTag, id);

    if (response) {
      yield put(appActions.deleteTagSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting tag');
      yield put(appActions.deleteTagFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting tag:', error);
    yield put(appActions.deleteTagFailure(error));
    if (reject) reject(error);
  }
}

//delete treatment

function* handleDeleteTreatment(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteTreatment, id);
    if (response) {
      yield put(appActions.deleteTreatmentSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting treatment');
      yield put(appActions.deleteTreatmentFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting treatment:', error);
    yield put(appActions.deleteTreatmentFailure(error));
    if (reject) reject(error);
  }
}

//delete permission

function* handleDeletePermission(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deletePermission, id);
    if (response) {
      yield put(appActions.deletePermissionSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting permission');
      yield put(appActions.deletePermissionFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting permission:', error);
    yield put(appActions.deletePermissionFailure(error));
    if (reject) reject(error);
  }
}

//delete template

function* handleDeleteTemplate(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteTemplate, id);
    if (response) {
      yield put(appActions.deleteTemplateSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting template');
      yield put(appActions.deleteTemplateFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting template:', error);
    yield put(appActions.deleteTemplateFailure(error));
    if (reject) reject(error);
  }
}

//delete medicalrecord

function* handleDeleteMedicalRecord(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteMedicalRecord, id);

    if (response) {
      yield put(appActions.deleteMedicalRecordSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting medical record');
      yield put(appActions.deleteMedicalRecordFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting medical record:', error);
    yield put(appActions.deleteMedicalRecordFailure(error));
    if (reject) reject(error);
  }
}

//delete followup

function* handleDeleteFollowup(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteFollowup, id);

    if (response) {
      yield put(appActions.deleteFollowupSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting followup');
      yield put(appActions.deleteFollowupFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting followup:', error);
    yield put(appActions.deleteFollowupFailure(error));
    if (reject) reject(error);
  }
}

//delete notificationSetting

function* handleDeleteNotificationSetting(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteNotificationSetting, id);

    if (response) {
      yield put(appActions.deleteNotificationSettingSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting notification setting');
      yield put(appActions.deleteNotificationSettingFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting notification setting:', error);
    yield put(appActions.deleteNotificationSettingFailure(error));
    if (reject) reject(error);
  }
}

//delete notification

function* handleDeleteNotification(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteNotification, id);

    if (response) {
      yield put(appActions.deleteNotificationSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting notification');
      yield put(appActions.deleteNotificationFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting notification:', error);
    yield put(appActions.deleteNotificationFailure(error));
    if (reject) reject(error);
  }
}


//delete message

function* handleDeleteMessage(action) {
  const { id, resolve, reject } = action.payload;
  try {
    const response = yield call(deleteMessage, id);

    if (response) {
      yield put(appActions.deleteMessageSuccess(id));
      if (resolve) resolve(response.data || {});
    } else {
      const error = new Error(response.data?.message || 'Error deleting message');
      yield put(appActions.deleteMessageFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    console.error('Error deleting message:', error);
    yield put(appActions.deleteMessageFailure(error));
    if (reject) reject(error);
  }
}


function* handleFetchTokenAppointmentByPatientId(action) {
  const { patientId, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchTokenAppointmentsByPatient, patientId);
    if (response && response.data) {
      yield put(appActions.fetchTokenAppointmentByPatientIdSuccess(response.data));
      if (resolve) resolve(response.data);
    } else {
      const error = new Error('No data found');
      yield put(appActions.fetchTokenAppointmentByPatientIdFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchTokenAppointmentByPatientIdFailure(error));
    if (reject) reject(error);
  }
}



//fetch doctorbyid
function* handleFetchDoctorById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchDoctorById, id);
    console.log('API response:', response);

    if (response && response.user) {
      yield put(appActions.fetchDoctorByIdSuccess(response.user));
      if (resolve) resolve(response.user);
    } else {
      const error = new Error('No user data found');
      yield put(appActions.fetchDoctorByIdFailure(error));
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchDoctorByIdFailure(error));
    if (reject) reject(error);
  }
}

//fetch patientById
function* handleFetchPatientById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchPatientById, id);

    if (response && response.patient) {
      yield put(appActions.fetchPatientByIdSuccess(response.patient));
      
      if (resolve) resolve(response.patient);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchPatientByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchPatientByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch areaById
function* handleFetchAreaById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchAreaById, id);

    if (response && response.area) {
      yield put(appActions.fetchAreaByIdSuccess(response.area));
      
      if (resolve) resolve(response.area);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchAreaByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchAreaByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch ClinicById
function* handleFetchClinicById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchClinicById, id);

    if (response && response.clinic) {
      yield put(appActions.fetchClinicByIdSuccess(response.clinic));
      
      if (resolve) resolve(response.clinic);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchClinicByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchClinicByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch staffById
function* handleFetchStaffById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchStaffById, id);

    if (response && response.staff) {
      yield put(appActions.fetchStaffByIdSuccess(response.staff));
      
      if (resolve) resolve(response.staff);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchStaffByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchStaffByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch tagById
function* handleFetchTagById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchTagById, id);

    if (response && response.tag) {
      yield put(appActions.fetchTagByIdSuccess(response.tag));
      
      if (resolve) resolve(response.tag);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchTagByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchTagByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch billingById
function* handleFetchBillingById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchBillingById, id);

    if (response && response.billing) {
      yield put(appActions.fetchBillingByIdSuccess(response.billing));
      
      if (resolve) resolve(response.billing);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchBillingByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchTagByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch medicalrecordById
function* handleFetchMedicalRecordById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchMedicalRecordById, id);

    if (response && response.medicalrecord) {
      yield put(appActions.fetchMedicalRecordByIdSuccess(response.medicalrecord));
      
      if (resolve) resolve(response.medicalrecord);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchMedicalRecordByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchMedicalRecordByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch PermissionById
function* handleFetchPermissionById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchPermissionById, id);

    if (response && response.permission) {
      yield put(appActions.fetchPermissionByIdSuccess(response.permission));
      
      if (resolve) resolve(response.permission);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchPermissionByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchPermissionByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch templateById
function* handleFetchTemplateById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchTemplateById, id);

    if (response && response.template) {
      yield put(appActions.fetchTemplateByIdSuccess(response.template));
      
      if (resolve) resolve(response.template);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchTemplateByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchTemplateByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch notificationById
function* handleFetchNotificationById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchNotificationById, id);

    if (response && response.notification) {
      yield put(appActions.fetchNotificationByIdSuccess(response.notification));
      
      if (resolve) resolve(response.notification);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchNotificationByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchNotificationByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch notificationById
function* handleFetchNotificationSettingById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchNotificationSettingById, id);

    if (response && response.notificationSetting) {
      yield put(appActions.fetchNotificationSettingByIdSuccess(response.notificationSetting));
      
      if (resolve) resolve(response.notificationSetting);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchNotificationSettingByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchNotificationSettingByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch tokenappointmentById
function* handleFetchTokenAppointmentById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchTokenAppointmentById, id);

    if (response && response.tokenappointment) {
      yield put(appActions.fetchTokenAppointmentByIdSuccess(response.tokenappointment));
      
      if (resolve) resolve(response.tokenappointment);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchTokenAppointmentByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchTokenAppointmentByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch messageById
function* handleFetchMessageById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchMessageById, id);

    if (response && response.message) {
      yield put(appActions.fetchMessageByIdSuccess(response.message));
      
      if (resolve) resolve(response.message);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchMessageByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchMessageByIdFailure(error));
    
    if (reject) reject(error);
  }
}

//fetch followupById
function* handleFetchFollowupById(action) {
  const { id, resolve, reject } = action.payload;

  try {
    const response = yield call(fetchFollowupById, id);

    if (response && response.followup) {
      yield put(appActions.fetchFollowupByIdSuccess(response.followup));
      
      if (resolve) resolve(response.followup);
    } else {
      const error = new Error('No data found');
      
      yield put(appActions.fetchFollowupByIdFailure(error));
      
      if (reject) reject(error);
    }
  } catch (error) {
    yield put(appActions.fetchFollowupByIdFailure(error));
    
    if (reject) reject(error);
  }
}



//update doctor
function* handleUpdateDoctor(action) {
  try {
    const { doctorId, doctorData } = action.payload;

    const response = yield call(updateDoctorById, doctorId, doctorData);

    if (response && response.user) {
      yield put(appActions.updateDoctorSuccess({ data: response.user }));
    } else {
      yield put(appActions.updateDoctorFailure('No doctor information updated'));
    }
  } catch (error) {
    yield put(appActions.updateDoctorFailure(error.message || 'Failed to update doctor'));
  }
}


//Update Patient

function* handleUpdatePatient(action) {
  try {
    const { patientId, patientData } = action.payload;
    const response = yield call(updatePatientById, patientId, patientData);

    if (response && response.patient) {
      yield put(appActions.updatePatientSuccess({ data: response.patient }));
    } else {
      yield put(appActions.updatePatientFailure('No patient information updated'));
    }
  } catch (error) {
    yield put(appActions.updatePatientFailure(error.message || 'Failed to update patient'));
  }
}

//update area
function* handleUpdateArea(action) {
  try {
    const { areaId, areaData } = action.payload;
    const response = yield call(updateAreaById, areaId, areaData);

    if (response && response.area) {
      yield put(appActions.updateAreaSuccess({ data: response.area }));
    } else {
      yield put(appActions.updateAreaFailure('No area information updated'));
    }
  } catch (error) {
    yield put(appActions.updateAreaFailure(error.message || 'Failed to update area'));
  }
}

//update clinic
function* handleUpdateClinic(action) {
  try {
    const { clinicId, clinicData } = action.payload;
    const response = yield call(updateClinicById, clinicId, clinicData);

    if (response && response.clinic) {
      yield put(appActions.updateClinicSuccess({ data: response.clinic }));
    } else {
      yield put(appActions.updateClinicFailure('No clinic information updated'));
    }
  } catch (error) {
    yield put(appActions.updateClinicFailure(error.message || 'Failed to update clinic'));
  }
}

//update staff
function* handleUpdateStaff(action) {
  try {
    const { staffId, staffData } = action.payload;
    const response = yield call(updateStaffById, staffId, staffData);

    if (response && response.staff){
      yield put(appActions.updateStaffSuccess({ data: response.staff }));
    } else {
      yield put(appActions.updateStaffFailure('No staff information updated'));
    }
  } catch (error) {
    yield put(appActions.updateStaffFailure(error.message || 'Failed to update staff'));
  }
}

//update tag
function* handleUpdateTag(action) {
  try {
    const { tagId, tagData } = action.payload;
    const response = yield call(updateTagById, tagId, tagData);

    if (response && response.tag){
      yield put(appActions.updateTagSuccess({ data: response.tag }));
    } else {
      yield put(appActions.updateTagFailure('No tag information updated'));
    }
  } catch (error) {
    yield put(appActions.updateTagFailure(error.message || 'Failed to update tag'));
  }
}

//update billing
function* handleUpdateBilling(action) {
  try {
    const { billingId, billingData } = action.payload;
    const response = yield call(updateBillingById, billingId, billingData);

    if (response && response.billing){
      yield put(appActions.updateBillingSuccess({ data: response.billing }));
    } else {
      yield put(appActions.updateBillingFailure('No billing information updated'));
    }
  } catch (error) {
    yield put(appActions.updateBillingFailure(error.message || 'Failed to update billing'));
  }
}

//update medicalrecord
function* handleUpdateMedicalRecord(action) {
  try {
    const { medicalRecordId, medicalRecordData } = action.payload;
    const response = yield call(updateMedicalRecordById, medicalRecordId, medicalRecordData);

    if (response && response.medicalrecord){
      yield put(appActions.updateMedicalRecordSuccess({ data: response.medicalrecord }));
    } else {
      yield put(appActions.updateMedicalRecordFailure('No medicalrecord information updated'));
    }
  } catch (error) {
    yield put(appActions.updateMedicalRecordFailure(error.message || 'Failed to update medicalrecord'));
  }
}

//update permission
function* handleUpdatePermission(action) {
  try {
    const { permissionId, permissionData } = action.payload;
    const response = yield call(updatePermissionById, permissionId, permissionData);

    if (response && response.permission){
      yield put(appActions.updatePermissionSuccess({ data: response.permission }));
    } else {
      yield put(appActions.updatePermissionFailure('No permission information updated'));
    }
  } catch (error) {
    yield put(appActions.updatePermissionFailure(error.message || 'Failed to update permission'));
  }
}

//update template
function* handleUpdateTemplate(action) {
  try {
    const { templateId, templateData } = action.payload;
    const response = yield call(updateTemplateById, templateId, templateData);

    if (response && response.template){
      yield put(appActions.updateTemplateSuccess({ data: response.template }));
    } else {
      yield put(appActions.updateTemplateFailure('No template information updated'));
    }
  } catch (error) {
    yield put(appActions.updateTemplateFailure(error.message || 'Failed to update template'));
  }
}

//update notification
function* handleUpdateNotification(action) {
  try {
    const { notificationId, notificationData } = action.payload;
    const response = yield call(updateNotificationById, notificationId, notificationData);

    if (response && response.notification){
      yield put(appActions.updateNotificationSuccess({ data: response.notification }));
    } else {
      yield put(appActions.updateNotificationFailure('No notification information updated'));
    }
  } catch (error) {
    yield put(appActions.updateNotificationFailure(error.message || 'Failed to update notification'));
  }
}

//update notification
function* handleUpdateNotificationSetting(action) {
  try {
    const { notificationSettingId, notificationSettingData } = action.payload;
    const response = yield call(updateNotificationSettingById, notificationSettingId, notificationSettingData);

    if (response && response.notification){
      yield put(appActions.updateNotificationSettingSuccess({ data: response.notificationSetting }));
    } else {
      yield put(appActions.updateNotificationSettingFailure('No notificationSetting information updated'));
    }
  } catch (error) {
    yield put(appActions.updateNotificationSettingFailure(error.message || 'Failed to update notificationsetting'));
  }
}

function* handleUpdateMessage(action) {
  try {
    const { messageId, messageData } = action.payload;
    const response = yield call(updateMessageById, messageId, messageData);

    if (response && response.message){
      yield put(appActions.updateMessageSuccess({ data: response.message }));
    } else {
      yield put(appActions.updateMessageFailure('No message information updated'));
    }
  } catch (error) {
    yield put(appActions.updateMessageFailure(error.message || 'Failed to update message'));
  }
}

function* handleUpdateFollowup(action) {
  try {
    const { followupId, followupData } = action.payload;
    const response = yield call(updateFollowupById, followupId, followupData);

    if (response && response.followup){
      yield put(appActions.updateFollowupSuccess({ data: response.followup }));
    } else {
      yield put(appActions.updateFollowupFailure('No followup information updated'));
    }
  } catch (error) {
    yield put(appActions.updateFollowupFailure(error.message || 'Failed to update followup'));
  }
}

//update appointment
function* handleUpdateTokenAppointment(action) {
  try {
    const { appointmentId, appointmentData } = action.payload;
    const response = yield call(updateTokenAppointmentById, appointmentId, appointmentData);

    if (response && response.tokenappointment){
      yield put(appActions.updateTokenAppointmentSuccess({ data: response.tokenappointment }));
    } else {
      yield put(appActions.updateTokenAppointmentFailure('No appointment information updated'));
    }
  } catch (error) {
    yield put(appActions.updateTokenAppointmentFailure(error.message || 'Failed to update appointment'));
  }
}


export function* watchDoctorSagas() {
  yield takeLatest(appTypes.FETCH_DOCTORS_REQUEST, handleFetchDoctors);
  yield takeLatest(appTypes.DELETE_DOCTOR_REQUEST, handleDeleteDoctor);
  yield takeLatest(appTypes.ADD_DOCTOR_REQUEST, handleAddDoctor);
  yield takeLatest(appTypes.UPDATE_DOCTOR_REQUEST, handleUpdateDoctor);
  yield takeLatest(appTypes.FETCH_DOCTOR_BY_ID_REQUEST, handleFetchDoctorById);
}

export function* watchFetchDoctornameSagas() {
  yield takeLatest(appTypes.FETCH_DOCTOR_NAMES_REQUEST, handleFetchDoctorname);
}

export function* watchFetchClinicnameSagas() {
  yield takeLatest(appTypes.FETCH_CLINIC_NAMES_REQUEST, handleFetchClinicname);
}

export function* watchFetchPatientnameSagas() {
  yield takeLatest(appTypes.FETCH_PATIENT_NAMES_REQUEST, handleFetchPatientname);
}

export function* watchPatientSagas() {
  yield takeLatest(appTypes.FETCH_PATIENTS_REQUEST, handleFetchPatients);
  yield takeLatest(appTypes.DELETE_PATIENT_REQUEST, handleDeletePatient);
  yield takeLatest(appTypes.ADD_PATIENT_REQUEST, handleAddPatient);
  yield takeLatest(appTypes.UPDATE_PATIENT_REQUEST, handleUpdatePatient);
  yield takeLatest(appTypes.FETCH_PATIENT_BY_ID_REQUEST, handleFetchPatientById);
}

export function* watchStaffSagas() {
  yield takeLatest(appTypes.FETCH_STAFFS_REQUEST, handleFetchStaffs);
  yield takeLatest(appTypes.DELETE_STAFF_REQUEST, handleDeleteStaff);
  yield takeLatest(appTypes.ADD_STAFF_REQUEST, handleAddStaff);
  yield takeLatest(appTypes.UPDATE_STAFF_REQUEST, handleUpdateStaff);
  yield takeLatest(appTypes.FETCH_STAFF_BY_ID_REQUEST, handleFetchStaffById);
}

export function* watchTaskSagas() {
  yield takeLatest(appTypes.FETCH_TASKS_REQUEST, handleFetchTasks);
  yield takeLatest(appTypes.ADD_TASK_REQUEST, handleAddTask);
}

export function* watchClinicSagas() {
  yield takeLatest(appTypes.FETCH_CLINICS_REQUEST, handleFetchClinics);
  yield takeLatest(appTypes.ADD_CLINIC_REQUEST, handleAddClinic);
  yield takeLatest(appTypes.DELETE_CLINIC_REQUEST, handleDeleteClinic);
  yield takeLatest(appTypes.UPDATE_CLINIC_REQUEST, handleUpdateClinic);
  yield takeLatest(appTypes.FETCH_CLINIC_BY_ID_REQUEST, handleFetchClinicById);
}

export function* watchFetchTokenAppointmentSagas() {
  yield takeLatest(appTypes.FETCH_TOKENAPPOINTMENT_REQUEST, handleFetchTokenAppointment);
  yield takeLatest(appTypes.DELETE_TOKENAPPOINTMENT_REQUEST, handleDeleteTokenAppointment);
  yield takeLatest(appTypes.ADD_TOKENAPPOINTMENT_REQUEST, handleAddTokenAppointment);
  yield takeLatest(appTypes.UPDATE_TOKENAPPOINTMENT_REQUEST, handleUpdateTokenAppointment);
  yield takeLatest(appTypes.FETCH_TOKENAPPOINTMENT_BY_ID_REQUEST, handleFetchTokenAppointmentById);
}

export function* watchFetchBillingSagas() {
  yield takeLatest(appTypes.FETCH_BILLINGS_REQUEST, handleFetchBillings);
  yield takeLatest(appTypes.DELETE_BILLING_REQUEST, handleDeleteBilling);
  yield takeLatest(appTypes.ADD_BILLING_REQUEST, handleAddBilling);
  yield takeLatest(appTypes.UPDATE_BILLING_REQUEST, handleUpdateBilling);
  yield takeLatest(appTypes.FETCH_BILLING_BY_ID_REQUEST, handleFetchBillingById);
}

export function* watchAreaSagas() {
  yield takeLatest(appTypes.FETCH_AREAS_REQUEST, handleFetchAreas);
  yield takeLatest(appTypes.DELETE_AREA_REQUEST, handleDeleteArea);
  yield takeLatest(appTypes.ADD_AREA_REQUEST, handleAddArea);
  yield takeLatest(appTypes.UPDATE_AREA_REQUEST, handleUpdateArea);
  yield takeLatest(appTypes.FETCH_AREA_BY_ID_REQUEST, handleFetchAreaById);
}

export function* watchTagsSagas() {
  yield takeLatest(appTypes.FETCH_TAGS_REQUEST, handleFetchTags);
  yield takeLatest(appTypes.DELETE_TAG_REQUEST, handleDeleteTag);
  yield takeLatest(appTypes.ADD_TAG_REQUEST, handleAddTag);
  yield takeLatest(appTypes.UPDATE_TAG_REQUEST, handleUpdateTag);
  yield takeLatest(appTypes.FETCH_TAG_BY_ID_REQUEST, handleFetchTagById);
}

export function* watchTreatmentSagas() {
  yield takeLatest(appTypes.FETCH_TREATMENTS_REQUEST, handleFetchTreatments);
  yield takeLatest(appTypes.ADD_TREATMENT_REQUEST, handleAddTreatment);
  yield takeLatest(appTypes.DELETE_TREATMENT_REQUEST, handleDeleteTreatment);
}

export function* watchFetchPermissionSagas() {
  yield takeLatest(appTypes.FETCH_PERMISSIONS_REQUEST, handleFetchPermissions);
  yield takeLatest(appTypes.ADD_PERMISSION_REQUEST, handleAddPermission);
  yield takeLatest(appTypes.DELETE_PERMISSION_REQUEST, handleDeletePermission);
  yield takeLatest(appTypes.UPDATE_PERMISSION_REQUEST, handleUpdatePermission);
  yield takeLatest(appTypes.FETCH_PERMISSION_BY_ID_REQUEST, handleFetchPermissionById);
}

export function* watchFetchTemplateSagas() {
  yield takeLatest(appTypes.FETCH_TEMPLATES_REQUEST, handleFetchTemplates);
  yield takeLatest(appTypes.DELETE_TEMPLATE_REQUEST, handleDeleteTemplate);
  yield takeLatest(appTypes.ADD_TEMPLATE_REQUEST, handleAddTemplate);
  yield takeLatest(appTypes.UPDATE_TEMPLATE_REQUEST, handleUpdateTemplate);
  yield takeLatest(appTypes.FETCH_TEMPLATE_BY_ID_REQUEST, handleFetchTemplateById);
}

function* watchFetchTokenAppointmentByPatientId() {
  yield takeLatest(appTypes.FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_REQUEST, handleFetchTokenAppointmentByPatientId);
}

export function* watchFetchMedicalrecordsSagas() {
  yield takeLatest(appTypes.FETCH_MEDICALRECORDS_REQUEST, handleFetchMedicalRecords);
  yield takeLatest(appTypes.DELETE_MEDICALRECORD_REQUEST, handleDeleteMedicalRecord);
  yield takeLatest(appTypes.ADD_MEDICALRECORD_REQUEST, handleAddMedicalRecord);
  yield takeLatest(appTypes.UPDATE_MEDICALRECORD_REQUEST, handleUpdateMedicalRecord);
  yield takeLatest(appTypes.FETCH_MEDICALRECORD_BY_ID_REQUEST, handleFetchMedicalRecordById);
}

export function* watchFetchFollowupsSagas() {
  yield takeLatest(appTypes.FETCH_FOLLOWUPS_REQUEST, handleFetchFollowups);
  yield takeLatest(appTypes.ADD_FOLLOWUP_REQUEST, handleAddFollowup);
  yield takeLatest(appTypes.DELETE_FOLLOWUP_REQUEST, handleDeleteFollowup);
  yield takeLatest(appTypes.UPDATE_FOLLOWUP_REQUEST, handleUpdateFollowup);
  yield takeLatest(appTypes.FETCH_FOLLOWUP_BY_ID_REQUEST, handleFetchFollowupById);
}

export function* watchFetchNotificationSettingSagas() {
  yield takeLatest(appTypes.FETCH_NOTIFICATION_SETTING_REQUEST, handleFetchNotificationSettings);
  yield takeLatest(appTypes.ADD_NOTIFICATIONSETTING_REQUEST, handleaddNotificationSetting);
  yield takeLatest(appTypes.DELETE_NOTIFICATION_SETTING_REQUEST, handleDeleteNotificationSetting);
  yield takeLatest(appTypes.UPDATE_NOTIFICATIONSETTING_REQUEST, handleUpdateNotificationSetting);
  yield takeLatest(appTypes.FETCH_NOTIFICATIONSETTING_BY_ID_REQUEST, handleFetchNotificationSettingById);
}

function* watchFetchMessagesSagas() {
  yield takeLatest(appTypes.FETCH_MESSAGES_REQUEST, handleFetchMessages);
  yield takeLatest(appTypes.ADD_MESSAGE_REQUEST, handleAddMessage);
  yield takeLatest(appTypes.DELETE_MESSAGE_REQUEST, handleDeleteMessage);
  yield takeLatest(appTypes.UPDATE_MESSAGE_REQUEST, handleUpdateMessage);
  yield takeLatest(appTypes.FETCH_MESSAGE_BY_ID_REQUEST, handleFetchMessageById);
}

function* watchFetchNotificationsSagas() {
  yield takeLatest(appTypes.FETCH_NOTIFICATIONS_REQUEST, handleFetchNotifications);
  yield takeLatest(appTypes.ADD_NOTIFICATION_REQUEST, handleAddNotification);
  yield takeLatest(appTypes.DELETE_NOTIFICATION_REQUEST, handleDeleteNotification);
  yield takeLatest(appTypes.UPDATE_NOTIFICATION_REQUEST, handleUpdateNotification);
  yield takeLatest(appTypes.FETCH_NOTIFICATION_BY_ID_REQUEST, handleFetchNotificationById);
}

function* watchFetchDrProfilesSagas() {
}

export function* watchSagas() { }

export default function* rootSaga() {
  yield all([
    fork(watchPatientSagas),
    fork(watchDoctorSagas),
    fork(watchStaffSagas),
    fork(watchTaskSagas),
    fork(watchClinicSagas),
    fork(watchFetchTokenAppointmentSagas),
    fork(watchFetchBillingSagas),
    fork(watchAreaSagas),
    fork(watchTagsSagas),
    fork(watchTreatmentSagas),
    fork(watchFetchDoctornameSagas),
    fork(watchFetchClinicnameSagas),
    fork(watchFetchPatientnameSagas),
    fork(watchFetchPermissionSagas),
    fork(watchFetchTemplateSagas),
    fork(watchFetchTokenAppointmentByPatientId),
    fork(watchFetchMedicalrecordsSagas),
    fork(watchFetchFollowupsSagas),
    fork(watchFetchNotificationSettingSagas),
    fork(watchFetchMessagesSagas),
    fork(watchFetchNotificationsSagas),
    fork(watchFetchDrProfilesSagas),
  ]);
}