import React, { useState, useEffect, useMemo } from "react";
import { FormBox } from "../doctor/style";
import * as Yup from 'yup';
import { Grid, Typography } from "@mui/material";
import InputComponent from "src/components/shared/Form/Input";
import Select from 'src/components/shared/Form/Select';
import { Button } from "src/components/shared";
import { addBillingRequest, updateBillingRequest, fetchBillingByIdRequest, fetchDoctornameRequest, fetchPatientnameRequest, fetchTokenAppointmentByPatientIdRequest } from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';
import toast from "src/utils/toast";
import Datepicker from "src/components/shared/Form/Datepicker";
import { useParams, useNavigate } from 'react-router-dom';

function AddEditBilling() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors);
    const patients = useSelector((state) => state.app.patients);
    const { id } = useParams(); // Capture id from URL for edit
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);
    const [tokenAppointments, setTokenAppointments] = useState([]);
    const [selectedPatient, setSelectedPatient] = useState('');

    const [initialValues, setInitialValues] = useState({
        paymentFor: '',
        amount: '',
        date: dayjs().format('YYYY-MM-DD'),
        method: '',
        type: '',
        user: '',
        patient: '',
        tokenAppointment: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                // Fetch doctors and patients
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchDoctornameRequest(resolve, reject))),
                    new Promise((resolve, reject) => dispatch(fetchPatientnameRequest(resolve, reject)))
                ]);

                // Fetch billing data if editing
                if (id) {
                    await new Promise((resolve, reject) =>
                        dispatch(fetchBillingByIdRequest(id, resolve, reject))
                    ).then((billing) => {
                        setInitialValues({
                            paymentFor: billing.paymentFor || '',
                            amount: billing.amount || '',
                            date: billing.date || dayjs().format('YYYY-MM-DD'),
                            method: billing.method || '',
                            type: billing.type || '',
                            user: billing.user || '',
                            patient: billing.patient || '',
                            tokenAppointment: billing.tokenAppointment || '',
                        });
                        handleFetchTokenAppointments(billing.patient); // Fetch token appointments for the selected patient
                    });
                }

                setIsLoading(false);
            } catch (error) {
                console.error(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    const handleFetchTokenAppointments = (patientId) => {
        dispatch(fetchTokenAppointmentByPatientIdRequest(patientId, (data) => {
            setTokenAppointments(data); // Update state with fetched token appointments
        }, (error) => {
            console.error('Error:', error);
        }));
    };

    const handlePatientChange = (e) => {
        const patientId = e.target.value;
        setSelectedPatient(patientId);
        handleFetchTokenAppointments(patientId);
    };

    const handleSubmit = async (values, { resetForm }) => {
        const billing = { ...values };

        try {
            if (id) {
                // Update existing billing record
                await new Promise((resolve, reject) => {
                    dispatch(updateBillingRequest(id, billing, resolve, reject));
                });
                toast.success('Billing Updated Successfully');
            } else {
                // Add new billing record
                await new Promise((resolve, reject) => {
                    dispatch(addBillingRequest(billing, resolve, reject));
                });
                toast.success('Billing Added Successfully');
            }
            resetForm();
            navigate('/billing'); // Redirect after submission
        } catch (error) {
            toast.error('Error handling billing');
        }
    };

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({
            label: doctor.name,
            value: doctor.id,
        }))
    ), [doctors]);

    const patientOptions = useMemo(() => (
        patients.map((patient) => ({
            label: patient.name,
            value: patient.id,
        }))
    ), [patients]);

    const tokenAppointmentOptions = useMemo(() => (
        tokenAppointments.map((appointment) => ({
            label: appointment.id, // Adjust based on how token appointment data should be displayed
            value: appointment.id,
        }))
    ), [tokenAppointments]);

    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                enableReinitialize // Important to load the data dynamically when editing
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    paymentFor: Yup.string().required("Please enter paymentFor!"),
                    amount: Yup.string().required('Please enter the amount!'),
                    date: Yup.string().required('Please select the date!'),
                    method: Yup.string().required('Please select the method!'),
                    type: Yup.string().required('Please select the type!'),
                    user: Yup.string().required('Please select the user!'),
                    patient: Yup.string().required('Please select the patient!'),
                    tokenAppointment: Yup.string().required('Please select the token appointment!'), // Validation for new field
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Typography variant="h4" color='#393A96' fontWeight='bold'>
                                {id ? 'Edit Billing' : 'Add Billing'}
                            </Typography>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Payment For
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="paymentFor"
                                    value={values.paymentFor}
                                    onChange={handleChange}
                                    error={touched.paymentFor && Boolean(errors.paymentFor)}
                                    helperText={touched.paymentFor && errors.paymentFor}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Amount
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="amount"
                                    value={values.amount}
                                    onChange={handleChange}
                                    error={touched.amount && Boolean(errors.amount)}
                                    helperText={touched.amount && errors.amount}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Pick Date
                                </Typography>
                                <Datepicker
                                    variant="outlined"
                                    fullWidth
                                    type="date"
                                    name="date"
                                    value={values.date}
                                    onChange={(value) => setFieldValue('date', dayjs(value).format('YYYY-MM-DD'))}
                                    error={touched.date && Boolean(errors.date)}
                                    helperText={touched.date && errors.date}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Type
                                </Typography>
                                <Select
                                    variant="outlined"
                                    fullWidth
                                    name="type"
                                    value={values.type}
                                    options={[
                                        { label: 'Credit', value: 'credit' },
                                        { label: 'Debit', value: 'debit' },
                                    ]}
                                    onChange={handleChange}
                                    error={touched.type && Boolean(errors.type)}
                                    helperText={touched.type && errors.type}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Method
                                </Typography>
                                <Select
                                    variant="outlined"
                                    fullWidth
                                    name="method"
                                    value={values.method}
                                    options={[
                                        { label: 'UPI', value: 'upi' },
                                        { label: 'Cash', value: 'cash' },
                                        { label: 'Card', value: 'card' },
                                        { label: 'Bank Transfer', value: 'bank_transfer' },
                                        { label: 'Cheque', value: 'cheque' },
                                    ]}
                                    onChange={handleChange}
                                    error={touched.method && Boolean(errors.method)}
                                    helperText={touched.method && errors.method}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='user'
                                    value={values.user}
                                    onChange={(e) => setFieldValue('user', e.target.value)}
                                    options={doctorOptions}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Patient
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='patient'
                                    value={values.patient}
                                    onChange={(e) => {
                                        handlePatientChange(e);
                                        setFieldValue('patient', e.target.value);
                                    }}
                                    options={patientOptions}
                                    placeholder="Select a patient"
                                    error={touched.patient && Boolean(errors.patient)}
                                    helperText={touched.patient && errors.patient}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Appointment
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='tokenAppointment'
                                    value={values.tokenAppointment}
                                    onChange={handleChange}
                                    options={tokenAppointmentOptions}
                                    placeholder="Select a token appointment"
                                    error={touched.tokenAppointment && Boolean(errors.tokenAppointment)}
                                    helperText={touched.tokenAppointment && errors.tokenAppointment}
                                />
                            </Grid>
                            <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '10px' }}>
                                <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                                    {id ? 'Update Billing' : 'Save Billing'}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </FormBox>
    );
}

export default AddEditBilling;
