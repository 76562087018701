import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Grid, Typography, Tooltip } from '@mui/material';
import { Formik } from 'formik';
import { addPatientRequest, updatePatientRequest, fetchPatientByIdRequest, fetchDoctornameRequest } from '../../store/appActions';
import { FormBox } from '../doctor/style';
import { Button } from 'src/components/shared';
import { useSnackbar } from 'notistack';
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';
import { useParams, useNavigate } from 'react-router-dom';

function AddPatient() {
  const dispatch = useDispatch();
  const doctors = useSelector((state) => state.app.doctors);
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const [initialValues, setInitialValues] = useState({
    fullName: '',
    contactNo: '',
    age: '',
    gender: '',
    user: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve, reject) => {
          dispatch(fetchDoctornameRequest(resolve, reject));
        });

        if (id) {
          await new Promise((resolve, reject) => {
            dispatch(fetchPatientByIdRequest(id, (patient) => {
              setInitialValues({
                fullName: patient.fullName || '',
                contactNo: patient.contactNo || '',
                age: patient.age || '',
                gender: patient.gender || '',
                user: patient.user || '',
              });
            }, reject));
          });
        }

        setIsLoading(false);
      } catch (error) {
        console.error('Failed to load data:', error);
        enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
        setIsLoading(false);
      }
    };

    fetchData();
  }, [dispatch, id, enqueueSnackbar]);

  const handleSubmit = async (values, { resetForm }) => {
    const patient = { ...values };
    try {
      console.log('Submitting:', patient)
      if (id) {
        await new Promise((resolve, reject) => {
          dispatch(updatePatientRequest(id, patient, resolve, reject));
        });
        enqueueSnackbar('patient updated successfully', { variant: 'success' });
      } else {
        await new Promise((resolve, reject) => {
          dispatch(addPatientRequest(patient, resolve, reject));
        });
        enqueueSnackbar('patient added successfully', { variant: 'success' });
      }

      resetForm();
      navigate('/patients');
    } catch (error) {
      console.error('Failed to save patient:', error.response?.data || error.message);
      enqueueSnackbar(error.response?.data.message || 'Failed to save patient', { variant: 'error' });
    }
  };
  

  const doctorOptions = useMemo(() => (
    doctors.map((doctor) => ({
      label: doctor.name,
      value: doctor.id,
    }))
  ), [doctors]);

  const genderOptions = [
    { label: 'Male', value: 'male' },
    { label: 'Female', value: 'female' },
  ];

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Please enter the patient's full name!"),
    contactNo: Yup.string()
      .required("Please enter the patient's contact number!")
      .matches(/^\d{10}$/, 'Contact number must be exactly 10 digits'),
    age: Yup.number()
      .required("Please enter the patient's age!")
      .positive('Age must be a positive number'),
    gender: Yup.string().required("Please select the patient's gender!"),
    user: Yup.string().required('Please select a doctor!'),
  });

  return (
    <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
          <form onSubmit={handleSubmit} noValidate>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
              <Typography
                variant="h4"
                color="#393A96"
                fontWeight="bold"
                sx={{ marginLeft: '2%' }}
              >
                {id ? 'Edit Patient' : 'Add Patient'}
              </Typography>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Full Name
                </Typography>
                <Tooltip title="Enter the patient's full name">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="fullName"
                    value={values.fullName}
                    onChange={handleChange}
                    error={touched.fullName && Boolean(errors.fullName)}
                    helperText={touched.fullName && errors.fullName}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Contact Number
                </Typography>
                <Tooltip title="Enter the patient's contact number">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="contactNo"
                    value={values.contactNo}
                    onChange={handleChange}
                    error={touched.contactNo && Boolean(errors.contactNo)}
                    helperText={touched.contactNo && errors.contactNo}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Age
                </Typography>
                <Tooltip title="Enter the patient's age">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="age"
                    value={values.age}
                    onChange={handleChange}
                    error={touched.age && Boolean(errors.age)}
                    helperText={touched.age && errors.age}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Gender
                </Typography>
                <Tooltip title="Select the patient's gender">
                  <Select
                    variant="outlined"
                    fullWidth
                    name="gender"
                    value={values.gender}
                    onChange={handleChange}
                    options={genderOptions}
                    placeholder="Select a gender"
                    error={touched.gender && Boolean(errors.gender)}
                    helperText={touched.gender && errors.gender}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Doctor
                </Typography>
                <Select
                  variant="outlined"
                  fullWidth
                  name="user"
                  value={values.user}
                  onChange={(e) => setFieldValue('user', e.target.value)}
                  options={doctorOptions}
                  placeholder="Select a doctor"
                  error={touched.user && Boolean(errors.user)}
                  helperText={touched.user && errors.user}
                />
              </Grid>
              <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                <Button variant="contained" type="submit" disabled={!isValid}>
                  {id ? 'Update Patient' : 'Add Patient'}
                </Button>
              </Grid>
            </Grid>
          </form>
        )}
      </Formik>
    </FormBox>
  );
}

export default AddPatient;

