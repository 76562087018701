import React, { useState, useEffect, useMemo } from 'react';
import { FormBox } from '../doctor/style';
import * as Yup from 'yup';
import { Grid, Typography, MenuItem, Select, TextField } from '@mui/material';
import { Button } from 'src/components/shared';
import { addTagRequest, updateTagRequest, fetchTagByIdRequest, fetchDoctornameRequest, fetchClinicnameRequest, fetchPatientnameRequest } from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';
import toast from "src/utils/toast";

function Tag() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors);
    const clinics = useSelector((state) => state.app.clinics);
    const patients = useSelector((state) => state.app.patients);
    const { id } = useParams(); 
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        tag: '',
        user: '',
        clinic: '',
        patient: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchDoctornameRequest(resolve, reject))),
                    new Promise((resolve, reject) => dispatch(fetchClinicnameRequest(resolve, reject))),
                    new Promise((resolve, reject) => dispatch(fetchPatientnameRequest(resolve, reject)))
                ]);
                
                if (id) { 
                    await new Promise((resolve, reject) => {
                        dispatch(fetchTagByIdRequest(id, (tag) => {
                            setInitialValues({
                                tag: tag.tag || '',
                                user: tag.user || '',
                                clinic: tag.clinic || '',
                                patient: tag.patient || '',
                            });
                        }, reject));
                    });
                }
                setIsLoading(false);
            } catch (error) {
                enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id, enqueueSnackbar]);

    const handleSubmit = async (values, { resetForm }) => {
        const tagData = { ...values, tag: values.tag.split(',').map(t => t.trim()) };

        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updateTagRequest(id, tagData, resolve, reject));
                });
                toast.success('Tag updated successfully');
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addTagRequest(tagData, resolve, reject));
                });
                toast.success('Tag added successfully');
            }

            resetForm();
            navigate('/tags');
        } catch (error) {
            console.log(error);
        }
    };

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => (
            <MenuItem key={doctor.id} value={doctor.id}>
                {doctor.name}
            </MenuItem>
        ))
    ), [doctors]);

    const clinicOptions = useMemo(() => (
        clinics.map((clinic) => (
            <MenuItem key={clinic.id} value={clinic.id}>
                {clinic.name}
            </MenuItem>
        ))
    ), [clinics]);

    const patientOptions = useMemo(() => (
        patients.map((patient) => (
            <MenuItem key={patient.id} value={patient.id}>
                {patient.name}
            </MenuItem>
        ))
    ), [patients]);

    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    tag: Yup.string().required("Please enter the tag!"),
                    user: Yup.string().required('Please select a user!'),
                    clinic: Yup.string().required('Please select a clinic!'),
                    patient: Yup.string().required('Please select a patient!'),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Typography
                                variant="h4"
                                color='#393A96'
                                fontWeight='bold'
                            >
                                {id ? 'Edit Tag' : 'Add Tag'}
                            </Typography>

                            <Typography variant="h5" fontWeight="bold">
                                Tag
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="tag"
                                value={values.tag}
                                onChange={handleChange}
                                error={touched.tag && Boolean(errors.tag)}
                                helperText={touched.tag && errors.tag}
                            />

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='user'
                                    value={values.user}
                                    onChange={(e) => setFieldValue('user', e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        {doctors.length === 0 ? 'No doctors available' : 'Select a doctor'}
                                    </MenuItem>
                                    {doctorOptions}
                                </Select>
                                {touched.user && errors.user && (
                                    <Typography variant='body2' color='error'>
                                        {errors.user}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Clinic
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='clinic'
                                    value={values.clinic}
                                    onChange={(e) => setFieldValue('clinic', e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        {clinics.length === 0 ? 'No clinics available' : 'Select a clinic'}
                                    </MenuItem>
                                    {clinicOptions}
                                </Select>
                                {touched.clinic && errors.clinic && (
                                    <Typography variant='body2' color='error'>
                                        {errors.clinic}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Patient
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='patient'
                                    value={values.patient}
                                    onChange={(e) => setFieldValue('patient', e.target.value)}
                                    displayEmpty
                                >
                                    <MenuItem value="" disabled>
                                        {patients.length === 0 ? 'No patients available' : 'Select a patient'}
                                    </MenuItem>
                                    {patientOptions}
                                </Select>
                                {touched.patient && errors.patient && (
                                    <Typography variant='body2' color='error'>
                                        {errors.patient}
                                    </Typography>
                                )}
                            </Grid>

                            <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '10px' }}>
                                <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                                    {isLoading ? (id ? 'Updating...' : 'Saving...') : (id ? 'Update Tag' : 'Save Tag')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </FormBox>
    );
}

export default Tag;
