import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import { Box, Typography, Grid, Tooltip, MenuItem, Select } from '@mui/material';
import InputComponent from 'src/components/shared/Form/Input';
import { Button } from 'src/components/shared';
import { Formik } from 'formik';
import {
  addDoctorRequest,
  fetchDoctorByIdRequest,
  updateDoctorRequest,
} from '../../store/appActions';
import { FormBox } from './style';
import storage from 'src/utils/storageUtils';
import { jwtDecode } from 'jwt-decode';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'src/utils/toast';

const staticTenants = [
  { id: '6638fe55137367f15f453d4b', name: 'demo' },
  { id: 'otherTenantId', name: 'otherTenant' },
];

function AddDoctor() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState({
    fullName: '',
    email: '',
    contactNo: '',
    password: '',
    userType: 'doctor',
    tenantId: '',
  });

  useEffect(() => {
    const fetchDoctor = async (doctorId) => {
      try {
        const token = storage.get('TOKEN');
        await new Promise((resolve, reject) => {
          dispatch(
            fetchDoctorByIdRequest(
              doctorId,
              (user) => {
                setInitialValues({
                  fullName: user.fullName || '',
                  contactNo: user.contactNo || '',
                  email: user.email || '',
                  userType: user.userType || 'doctor',
                  tenantId: user.tenant || '',
                });
                setIsLoading(false);
                console.log('user fetched:', user);
              },
              (error) => {
                console.error('Error fetching user:', error);
                setIsLoading(false);
              },
            ),
          );
        });
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    };

    if (id) {
      fetchDoctor(id);
    } else {
      setIsLoading(false);
    }
  }, [dispatch, id]);

  const handleSubmit = async (values, { resetForm }) => {
    const { password, ...doctorData } = values;
    
    const doctor = {
      ...doctorData,
      ...(id ? {} : { password }),
    };
  
    console.log('Submitting doctor data:', doctor);
  
    const token = storage.get('TOKEN');
    setIsLoading(true);
  
    try {
      const promise = new Promise((resolve, reject) => {
        if (id) {
          dispatch(updateDoctorRequest(id, { doctor, token, resolve, reject }));
        } else {
          dispatch(addDoctorRequest({ doctor, token, resolve, reject }));
        }
      });
  
      await promise;
      toast.success(`Doctor ${id ? 'Updated' : 'Added'} Successfully`);
      resetForm();
      navigate('/doctors');
    } catch (error) {
      console.error(`Failed to ${id ? 'update' : 'add'} doctor:`, error.message || error);
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <FormBox sx={{ marginTop: '20px', marginLeft: '5%' }}>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={Yup.object().shape({
            fullName: Yup.string().required("Please enter doctor's full name!"),
            email: Yup.string()
              .email('Invalid email format')
              .required("Please enter doctor's email!"),
            contactNo: Yup.string()
              .matches(/^\d{10}$/, 'Contact number must be a valid 10-digit number')
              .required("Please enter doctor's contact number!"),
            ...(id
              ? {}
              : {
                password: Yup.string()
                  .required('Please enter a password!')
                  .min(8, 'Password must be at least 8 characters long')
                  .matches(
                    /^[a-zA-Z0-9]{8,30}$/,
                    'Password must contain only alphanumeric characters',
                  ),
              }),
            userType: Yup.string().required('Please select user type!'),
            tenantId: Yup.string().required('Please select tenant ID!'),
          })}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
            <form onSubmit={handleSubmit} noValidate>
              <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
                {id ? 'Edit Doctor' : 'Add Doctor'}
              </Typography>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Full Name
                </Typography>
                <Tooltip title="Enter doctor's full name">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="fullName"
                    value={values.fullName}
                    onChange={handleChange}
                    error={touched.fullName && Boolean(errors.fullName)}
                    helperText={touched.fullName && errors.fullName}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Email
                </Typography>
                <Tooltip title="Enter doctor's email">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="email"
                    value={values.email}
                    onChange={handleChange}
                    error={touched.email && Boolean(errors.email)}
                    helperText={touched.email && errors.email}
                  />
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Contact Number
                </Typography>
                <Tooltip title="Enter doctor's contact number">
                  <InputComponent
                    variant="outlined"
                    fullWidth
                    name="contactNo"
                    value={values.contactNo}
                    onChange={handleChange}
                    error={touched.contactNo && Boolean(errors.contactNo)}
                    helperText={touched.contactNo && errors.contactNo}
                  />
                </Tooltip>
              </Grid>
              {!id && (
                <Grid item>
                  <Typography variant="h5" fontWeight="bold">
                    Password
                  </Typography>
                  <Tooltip title="Enter a password">
                    <InputComponent
                      variant="outlined"
                      fullWidth
                      type="password"
                      name="password"
                      value={values.password}
                      onChange={handleChange}
                      error={touched.password && Boolean(errors.password)}
                      helperText={touched.password && errors.password}
                    />
                  </Tooltip>
                </Grid>
              )}
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Usertype
                </Typography>
                <Tooltip title="Select user type">
                  <Select
                    variant="outlined"
                    fullWidth
                    name="userType"
                    value={values.userType}
                    onChange={handleChange}
                    error={touched.userType && Boolean(errors.userType)}
                    helperText={touched.userType && errors.userType}
                    disabled
                  >
                    <MenuItem value="doctor">Doctor</MenuItem>
                    <MenuItem value="frontdesk">Frontdesk</MenuItem>
                    <MenuItem value="user">User</MenuItem>
                  </Select>
                </Tooltip>
              </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  TenantId
                </Typography>
                <Tooltip title="Select tenant ID">
                  <Select
                    variant="outlined"
                    fullWidth
                    name="tenantId"
                    value={values.tenantId}
                    onChange={(e) => setFieldValue('tenantId', e.target.value)}
                    error={touched.tenantId && Boolean(errors.tenantId)}
                    helperText={touched.tenantId && errors.tenantId}
                  >
                    {staticTenants.map((tenant) => (
                      <MenuItem key={tenant.id} value={tenant.id}>
                        {tenant.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Tooltip>
              </Grid>
              <Grid item container justifyContent="center" sx={{ marginTop: '20px' }}>
                <Button variant="contained" type="submit">
                  {isLoading
                    ? id
                      ? 'Updating...'
                      : 'Saving...'
                    : id
                      ? 'Update Doctor'
                      : 'Save Doctor'}
                </Button>
              </Grid>
            </form>
          )}
        </Formik>

      </Grid>
    </FormBox>
  );
}

export default AddDoctor;
