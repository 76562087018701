import React, { useState, useEffect, useMemo } from 'react';
import { FormBox } from '../doctor/style';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';
import { Button } from 'src/components/shared';
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';
import Textarea from 'src/components/shared/Form/Textarea';
import { fetchMessageByIdRequest, addMessageRequest, updateMessageRequest, fetchClinicnameRequest } from '../../store/appActions';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { useParams, useNavigate } from 'react-router-dom';
import toast from 'src/utils/toast';

function Message() {
    const dispatch = useDispatch();
    const clinics = useSelector((state) => state.app.clinics);
    const { id } = useParams();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        language: '',
        title: '',
        message: '',
        clinic: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await new Promise((resolve, reject) => dispatch(fetchClinicnameRequest(resolve, reject)));

                if (id) {
                    await new Promise((resolve, reject) => dispatch(fetchMessageByIdRequest(id, resolve, reject)))
                        .then((message) => {
                            setInitialValues({
                                language: message.language || '',
                                title: message.title || '',
                                message: message.message || '',
                                clinic: message.clinic || '',
                            });
                        });
                }

                setIsLoading(false);
            } catch (error) {
                console.log(error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id]);

    const handleSubmit = async (values, { resetForm }) => {
        const messageData = { ...values };

        try {
            if (id) {
                await new Promise((resolve, reject) => dispatch(updateMessageRequest(id, messageData, resolve, reject)));
                toast.success('Message Updated Successfully');
            } else {
                await new Promise((resolve, reject) => dispatch(addMessageRequest(messageData, resolve, reject)));
                toast.success('Message Added Successfully');
                resetForm();
            }
            navigate('/messages');
        } catch (error) {
            console.log(error);
        }
    };

    const languageOptions = [
        { label: 'English', value: 'english' },
        { label: 'Spanish', value: 'spanish' },
    ];

    const clinicOptions = useMemo(() => (
        clinics.map((clinic) => ({
            label: clinic.name,
            value: clinic.id,
        }))
    ), [clinics]);

    return (
        <>
            <FormBox sx={{ marginTop: '20px' }}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        language: Yup.string().required("Please select a language!"),
                        title: Yup.string().required("Please select a title!"),
                        message: Yup.string().required("Please enter the message!"),
                        clinic: Yup.string().required("Please select a clinic!"),
                    })}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleSubmit, touched, errors, setFieldValue, isValid }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                <Typography variant="h4" color='#393A96' fontWeight='bold'>
                                    {id ? 'Edit Message' : 'Add Message'}
                                </Typography>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">Language</Typography>
                                    <Select
                                        variant="outlined"
                                        fullWidth
                                        name="language"
                                        value={values.language}
                                        onChange={handleChange}
                                        options={languageOptions}
                                        error={touched.language && Boolean(errors.language)}
                                        helperText={touched.language && errors.language}
                                    />
                                </Grid>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">Template</Typography>
                                    <InputComponent
                                        variant="outlined"
                                        fullWidth
                                        name="title"
                                        value={values.title}
                                        onChange={handleChange}
                                        error={touched.title && Boolean(errors.title)}
                                        helperText={touched.title && errors.title}
                                    />
                                </Grid>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">Message</Typography>
                                    <InputComponent
                                        variant="outlined"
                                        fullWidth
                                        name="message"
                                        value={values.message}
                                        onChange={handleChange}
                                        rows={4}
                                        error={touched.message && Boolean(errors.message)}
                                        helperText={touched.message && errors.message}
                                    />
                                </Grid>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">Clinic</Typography>
                                    <Select
                                        variant="outlined"
                                        fullWidth
                                        name="clinic"
                                        value={values.clinic}
                                        onChange={(e) => setFieldValue('clinic', e.target.value)}
                                        options={clinicOptions}
                                        placeholder="Select a clinic"
                                        error={touched.clinic && Boolean(errors.clinic)}
                                        helperText={touched.clinic && errors.clinic}
                                    />
                                </Grid>

                                <Grid container justifyContent="center" sx={{ marginTop: '20px' }}>
                                    <Button
                                        type="submit"
                                        variant="contained"
                                        sx={{ textAlign: 'center' }}
                                        disabled={!isValid}
                                    >
                                        {id ? 'Update Message' : 'Save Message'}
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </FormBox>
        </>
    );
}

export default Message;
