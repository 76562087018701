import React from "react";
import DoctorProfile from "../../components/profile/DoctorProfile";

function DoctorProfileContainer() {
    return (
        <>
            <container maxWidth='lg'>
            <DoctorProfile/>
            </container>
        </>
    )
}
export default DoctorProfileContainer;