import * as appTypes from './appTypes';

const initialState = {
  appLoading: true,
  doctor: [],
  clinic: [],
  task: [],
  doctors: [],
  patient: [],
  patients: [],
  staff: [],
  staffs: [],
  tasks: [],
  tokenappointment: [],
  tokenAppointments: [],
  clinics: [],
  billing: [],
  billings: [],
  treatment: [],
  treatments: [],
  area: [],
  areas: [],
  tag: [],
  tags: [],
  permission: [],
  permissions: [],
  template: [],
  templates: [],
  medicalrecord: [],
  medicalrecords: [],
  followups: [],
  followup: [],
  notificationSetting: [],
  notificationSettings: [],
  message: [],
  messages: [],
  notifications: [],
  notification: [],
  error: null,
};

export default (state = initialState, { type, ...payload }) => {
  switch (type) {

    case appTypes.ADD_DOCTOR_SUCCESS:
  return { ...state, isLoading: false, doctor: payload.data };

    case appTypes.ADD_DOCTOR_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add patient

    case appTypes.ADD_PATIENT_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_PATIENT_SUCCESS:
      return { ...state, isLoading: false, patient: [...state.patient, payload.data] };

    case appTypes.ADD_PATIENT_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add clinic
    case appTypes.ADD_CLINIC_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_CLINIC_SUCCESS:
      return { ...state, isLoading: false, clinic: [...state.clinic, payload.data] };

    case appTypes.ADD_CLINIC_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add tokenappointment
    case appTypes.ADD_TOKENAPPOINTMENT_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_TOKENAPPOINTMENT_SUCCESS:
      return { ...state, isLoading: false, tokenappointment: [...state.tokenappointment, payload.data] };

    case appTypes.ADD_TOKENAPPOINTMENT_FAILURE:
      return { ...state, isLoading: false, error: payload.error };



    //add task
    case appTypes.ADD_TASK_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_TASK_SUCCESS:
      return { ...state, isLoading: false, task: [...state.task, payload.data] };

    case appTypes.ADD_TASK_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add Staff
    case appTypes.ADD_STAFF_REQUEST:
      return { ...state, isLoading: true };

    case appTypes.ADD_STAFF_SUCCESS:
      return { ...state, isLoading: false, staff: [...state.staff, payload.data] };

    case appTypes.ADD_STAFF_FAILURE:
      return { ...state, isLoading: false, error: payload.error };


    //ADD AREA
    case appTypes.ADD_AREA_REQUEST:
      return { ...state, isLoading: true };

    case appTypes.ADD_AREA_SUCCESS:
      return { ...state, isLoading: false, area: [...state.area, payload.data] };

    case appTypes.ADD_AREA_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //ADD tag
    case appTypes.ADD_TAG_REQUEST:
      return { ...state, isLoading: true };

    case appTypes.ADD_TAG_SUCCESS:
      return { ...state, isLoading: false, tag: [...state.tag, payload.data] };

    case appTypes.ADD_TAG_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //ADD billing
    case appTypes.ADD_BILLING_REQUEST:
      return { ...state, isLoading: true };

    case appTypes.ADD_BILLING_SUCCESS:
      return { ...state, isLoading: false, billing: [...state.billing, payload.data] };

    case appTypes.ADD_BILLING_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add permission
    case appTypes.ADD_PERMISSION_REQUEST:
      return { ...state, isLoading: true };

    case appTypes.ADD_PERMISSION_SUCCESS:
      return { ...state, isLoading: false, permission: [...state.permission, payload.data] };

    case appTypes.ADD_PERMISSION_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add template
    case appTypes.ADD_TEMPLATE_REQUEST:
      return { ...state, isLoading: true };

    case appTypes.ADD_TEMPLATE_SUCCESS:
      return { ...state, isLoading: false, template: [...state.template, payload.data] };

    case appTypes.ADD_TEMPLATE_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add treatment
    case appTypes.ADD_TREATMENT_REQUEST:
      return { ...state, isLoading: true };

    case appTypes.ADD_TREATMENT_SUCCESS:
      return { ...state, isLoading: false, treatment: [...state.treatment, payload.data] };

    case appTypes.ADD_TREATMENT_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add medicalrecord
    case appTypes.ADD_MEDICALRECORD_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_MEDICALRECORD_SUCCESS:
      return { ...state, isLoading: false, medicalrecord: [...state.medicalrecord, payload.data] };

    case appTypes.ADD_MEDICALRECORD_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add followup
    case appTypes.ADD_FOLLOWUP_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_FOLLOWUP_SUCCESS:
      return { ...state, isLoading: false, followup: [...state.followup, payload.data] };

    case appTypes.ADD_FOLLOWUP_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //add notificationsetting
    case appTypes.ADD_NOTIFICATIONSETTING_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_NOTIFICATIONSETTING_SUCCESS:
      return { ...state, isLoading: false, notificationSetting: [...state.notificationSetting, payload.data] };

    case appTypes.ADD_NOTIFICATIONSETTING_FAILURE:
      return { ...state, isLoading: false, error: payload.error };


    //add notification
    case appTypes.ADD_NOTIFICATION_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_NOTIFICATION_SUCCESS:
      return { ...state, isLoading: false, notification: [...state.notification, payload.data] };

    case appTypes.ADD_NOTIFICATION_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

       //add message
    case appTypes.ADD_MESSAGE_REQUEST:
      return { ...state, isLoading: true };
    case appTypes.ADD_MESSAGE_SUCCESS:
      return { ...state, isLoading: false, message: [...state.message, payload.data] };

    case appTypes.ADD_MESSAGE_FAILURE:
      return { ...state, isLoading: false, error: payload.error };


    //get doctor name
    case appTypes.FETCH_DOCTOR_NAMES_SUCCESS:
      return { ...state, isLoading: false, doctors: payload.data };

    case appTypes.FETCH_DOCTOR_NAMES_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get clinicName
    case appTypes.FETCH_CLINIC_NAMES_SUCCESS:
      return { ...state, isLoading: false, clinics: payload.data };
    case appTypes.FETCH_CLINIC_NAMES_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get patientname
    case appTypes.FETCH_PATIENT_NAMES_SUCCESS:
      return { ...state, isLoading: false, patients: payload.data };
    case appTypes.FETCH_PATIENT_NAMES_FAILURE:
      return { ...state, isLoading: false, error: payload.error };



    //get doctor
    case appTypes.FETCH_DOCTORS_SUCCESS:
      return { ...state, isLoading: false, doctors: payload.data };
    case appTypes.FETCH_DOCTORS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };


    //getPatients
    case appTypes.FETCH_PATIENTS_SUCCESS:
      return { ...state, isLoading: false, patients: payload.data };
    case appTypes.FETCH_PATIENTS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //getStaff
    case appTypes.FETCH_STAFFS_SUCCESS:
      return { ...state, isLoading: false, staffs: payload.data };
    case appTypes.FETCH_STAFFS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get Tasks
    case appTypes.FETCH_TASKS_SUCCESS:
      return { ...state, isLoading: false, tasks: payload.data };
    case appTypes.FETCH_TASKS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get clinics
    case appTypes.FETCH_CLINICS_SUCCESS:
      return { ...state, isLoading: false, clinics: payload.data };
    case appTypes.FETCH_CLINICS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get Appointment
    case appTypes.FETCH_TOKENAPPOINTMENT_SUCCESS:
      return { ...state, isLoading: false, tokenAppointments: payload.data };
    case appTypes.FETCH_TOKENAPPOINTMENT_FAILURE:
      return { ...state, isLoading: false, error: payload.error, tokenAppointments: [] };

    //get billings
    case appTypes.FETCH_BILLINGS_SUCCESS:
      return { ...state, isLoading: false, billings: payload.data };
    case appTypes.FETCH_BILLINGS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get areas
    case appTypes.FETCH_AREAS_SUCCESS:
      return { ...state, isLoading: false, areas: payload.data };
    case appTypes.FETCH_AREAS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get tag
    case appTypes.FETCH_TAGS_SUCCESS:
      return { ...state, isLoading: false, tags: payload.data };
    case appTypes.FETCH_TAGS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get treatments
    case appTypes.FETCH_TREATMENTS_SUCCESS:
      return { ...state, isLoading: false, treatments: payload.data };
    case appTypes.FETCH_TREATMENTS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get permissions
    case appTypes.FETCH_PERMISSIONS_SUCCESS:
      return { ...state, isLoading: false, permissions: payload.data };
    case appTypes.FETCH_PERMISSIONS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get templates
    case appTypes.FETCH_TEMPLATES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        templates: payload.data,
      };
    case appTypes.FETCH_TEMPLATES_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get medicalrecords
    case appTypes.FETCH_MEDICALRECORDS_SUCCESS:
      return { ...state, isLoading: false, medicalRecords: payload.data };
    case appTypes.FETCH_MEDICALRECORDS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get followups
    case appTypes.FETCH_FOLLOWUPS_SUCCESS:
      return { ...state, isLoading: false, followups: payload.data };
    case appTypes.FETCH_FOLLOWUPS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get notificationSetting
    case appTypes.FETCH_NOTIFICATION_SETTING_SUCCESS:
      return { ...state, isLoading: false, notificationSettings: payload.data };
    case appTypes.FETCH_NOTIFICATION_SETTING_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get messages
    case appTypes.FETCH_MESSAGES_SUCCESS:
      return { ...state, isLoading: false, messages: payload.data };
    case appTypes.FETCH_MESSAGES_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //get notifications
    case appTypes.FETCH_NOTIFICATIONS_SUCCESS:
      return { ...state, isLoading: false, notifications: payload.data };
    case appTypes.FETCH_NOTIFICATIONS_FAILURE:
      return { ...state, isLoading: false, error: payload.error };



    //delete doctor
    case appTypes.DELETE_DOCTOR_SUCCESS:
      return {
        ...state,
        doctors: state.doctors
          ? state.doctors.filter((doctor) => doctor.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_DOCTOR_FAILURE:
      return { ...state, error: payload.error };

    //delete patient
    case appTypes.DELETE_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patients: state.patients
          ? state.patients.filter((patient) => patient.id !== payload.id)
          : [],
      };

    case appTypes.DELETE_PATIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    //delete tokenappointment
    case appTypes.DELETE_TOKENAPPOINTMENT_SUCCESS:
      return {
        ...state,
        tokenAppointments: state.tokenAppointments
          ? state.tokenAppointments.filter((tokenAppointment) => tokenAppointment.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_TOKENAPPOINTMENT_FAILURE:
      return { ...state, error: payload.error };

    //delete staff
    case appTypes.DELETE_STAFF_SUCCESS:
      return {
        ...state,
        staffs: state.staffs
          ? state.staffs.filter((staff) => staff.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_STAFF_FAILURE:
      return { ...state, error: payload.error };


    //delete area
    case appTypes.DELETE_AREA_SUCCESS:
      return {
        ...state,
        areas: state.areas
          ? state.areas.filter((area) => area.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_AREA_FAILURE:
      return { ...state, error: payload.error };

    //delete BILLING
    case appTypes.DELETE_BILLING_SUCCESS:
      return {
        ...state,
        billings: state.billings
          ? state.billings.filter((billing) => billing.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_BILLING_FAILURE:
      return { ...state, error: payload.error };


    //delete clinic
    case appTypes.DELETE_CLINIC_SUCCESS:
      return {
        ...state,
        clinics: state.clinics
          ? state.clinics.filter((clinic) => clinic.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_CLINIC_FAILURE:
      return { ...state, error: payload.error };

    //delete tag
    case appTypes.DELETE_TAG_SUCCESS:
      return {
        ...state,
        tags: state.tags
          ? state.tags.filter((tag) => tag.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_TAG_FAILURE:
      return { ...state, error: payload.error };

    //delete TREATMENT
    case appTypes.DELETE_TREATMENT_SUCCESS:
      return {
        ...state,
        treatments: state.treatments
          ? state.treatments.filter((treatment) => treatment.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_TREATMENT_FAILURE:
      return { ...state, error: payload.error };

    //delete permission
    case appTypes.DELETE_PERMISSION_SUCCESS:
      return {
        ...state,
        permissions: state.permissions
          ? state.permissions.filter((permission) => permission.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_PERMISSION_FAILURE:
      return { ...state, error: payload.error };

    //delete template
    case appTypes.DELETE_TEMPLATE_SUCCESS:
      return {
        ...state,
        templates: state.templates
          ? state.templates.filter((template) => template.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_TEMPLATE_FAILURE:
      return { ...state, error: payload.error };

    //delete medical record
    case appTypes.DELETE_MEDICALRECORD_SUCCESS:
      return {
        ...state,
        medicalRecords: state.medicalRecords
          ? state.medicalRecords.filter((medicalrecord) => medicalrecord.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_MEDICALRECORD_FAILURE:
      return { ...state, error: payload.error };

    //delete followup
    case appTypes.DELETE_FOLLOWUP_SUCCESS:
      return {
        ...state,
        followups: state.followups
          ? state.followups.filter((followup) => followup.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_FOLLOWUP_FAILURE:
      return { ...state, error: payload.error };

    //delete notifcationSetting

    case appTypes.DELETE_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        notificationSettings: state.notificationSettings
          ? state.notificationSettings.filter((notificationSetting) => notificationSetting.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_NOTIFICATION_SETTING_FAILURE:
      return { ...state, error: payload.error };

    //delete notification
    case appTypes.DELETE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        notifications: state.notifications
          ? state.notifications.filter((notification) => notification.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_NOTIFICATION_FAILURE:
      return { ...state, error: payload.error };

      //delete message
    case appTypes.DELETE_MESSAGE_SUCCESS:
      return {
        ...state,
        messages: state.messages
          ? state.messages.filter((message) => message.id !== payload.id)
          : [],
      };
    case appTypes.DELETE_MESSAGE_FAILURE:
      return { ...state, error: payload.error };


    case appTypes.FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_SUCCESS:
      return { ...state, isLoading: false, tokenappointments: payload.data };


    case appTypes.FETCH_TOKEN_APPOINTMENT_BY_PATIENT_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };


    //fetch  DOCTORById
    case appTypes.FETCH_DOCTOR_BY_ID_SUCCESS:
      return { ...state, isLoading: false, doctor: payload.data };

    case appTypes.FETCH_DOCTOR_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //fetch  patientById
    case appTypes.FETCH_PATIENT_BY_ID_SUCCESS:
      return { ...state, isLoading: false, patient: payload.data };

    case appTypes.FETCH_PATIENT_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //fetch  areaById
    case appTypes.FETCH_AREA_BY_ID_SUCCESS:
      return { ...state, isLoading: false, area: payload.data };

    case appTypes.FETCH_AREA_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //fetch  clinicById
    case appTypes.FETCH_CLINIC_BY_ID_SUCCESS:
      return { ...state, isLoading: false, clinic: payload.data };

    case appTypes.FETCH_CLINIC_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //fetch  staffById
    case appTypes.FETCH_STAFF_BY_ID_SUCCESS:
      return { ...state, isLoading: false, staff: payload.data };

    case appTypes.FETCH_STAFF_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

    //fetch  tagById
    case appTypes.FETCH_TAG_BY_ID_SUCCESS:
      return { ...state, isLoading: false, tag: payload.data };

    case appTypes.FETCH_TAG_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

      //fetch  billingById
    case appTypes.FETCH_BILLING_BY_ID_SUCCESS:
      return { ...state, isLoading: false, billing: payload.data };

    case appTypes.FETCH_BILLING_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };


    //fetch  permissionById
    case appTypes.FETCH_PERMISSION_BY_ID_SUCCESS:
      return { ...state, isLoading: false, permission: payload.data };

    case appTypes.FETCH_PERMISSION_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

      //fetch  medicalrecordById
    case appTypes.FETCH_MEDICALRECORD_BY_ID_SUCCESS:
      return { ...state, isLoading: false, medicalrecord: payload.data };

    case appTypes.FETCH_MEDICALRECORD_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

     //fetch  templateById
     case appTypes.FETCH_TEMPLATE_BY_ID_SUCCESS:
      return { ...state, isLoading: false, template: payload.data };

    case appTypes.FETCH_TEMPLATE_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

       //fetch  notificationById
     case appTypes.FETCH_NOTIFICATION_BY_ID_SUCCESS:
      return { ...state, isLoading: false, notification: payload.data };

    case appTypes.FETCH_NOTIFICATION_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

      //fetch  notificationsettingById
     case appTypes.FETCH_NOTIFICATIONSETTING_BY_ID_SUCCESS:
      return { ...state, isLoading: false, notificationSetting: payload.data };

    case appTypes.FETCH_NOTIFICATIONSETTING_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

      //fetch  tokenappointmentById
     case appTypes.FETCH_TOKENAPPOINTMENT_BY_ID_SUCCESS:
      return { ...state, isLoading: false, tokenappointment: payload.data };

    case appTypes.FETCH_TOKENAPPOINTMENT_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

      //fetch  messageById
     case appTypes.FETCH_MESSAGE_BY_ID_SUCCESS:
      return { ...state, isLoading: false, message: payload.data };

    case appTypes.FETCH_MESSAGE_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };

      //fetch  followupById
     case appTypes.FETCH_FOLLOWUP_BY_ID_SUCCESS:
      return { ...state, isLoading: false, followup: payload.data };

    case appTypes.FETCH_FOLLOWUP_BY_ID_FAILURE:
      return { ...state, isLoading: false, error: payload.error };


    //update doctor
    case appTypes.UPDATE_DOCTOR_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_DOCTOR_SUCCESS:
      return {
        ...state,
        isLoading: false,
        doctor: payload.data,
      };
    case appTypes.UPDATE_DOCTOR_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    //update patient
    case appTypes.UPDATE_PATIENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        patient: payload.data,
      };
    case appTypes.UPDATE_PATIENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    //update area
    case appTypes.UPDATE_AREA_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_AREA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        area: payload.data,
      };
    case appTypes.UPDATE_AREA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    //update clinic
    case appTypes.UPDATE_CLINIC_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_CLINIC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        area: payload.data,
      };
    case appTypes.UPDATE_CLINIC_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    //update staff
    case appTypes.UPDATE_STAFF_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        staff: payload.data,
      };
    case appTypes.UPDATE_STAFF_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    //update tag
    case appTypes.UPDATE_TAG_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_TAG_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tag: payload.data,
      };
    case appTypes.UPDATE_TAG_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

      //update billing
    case appTypes.UPDATE_BILLING_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_BILLING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tag: payload.data,
      };
    case appTypes.UPDATE_BILLING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

      //update MEDICALRECORD
    case appTypes.UPDATE_MEDICALRECORD_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_MEDICALRECORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        medicalrecord: payload.data,
      };
    case appTypes.UPDATE_MEDICALRECORD_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

      //update PERMISSION
    case appTypes.UPDATE_PERMISSION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_PERMISSION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        permission: payload.data,
      };
    case appTypes.UPDATE_PERMISSION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

      //update template
    case appTypes.UPDATE_TEMPLATE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_TEMPLATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        template: payload.data,
      };
    case appTypes.UPDATE_TEMPLATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

    //update notification
    case appTypes.UPDATE_NOTIFICATION_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_NOTIFICATION_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notification: payload.data,
      };
    case appTypes.UPDATE_NOTIFICATION_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

      //update notificationSetting
    case appTypes.UPDATE_NOTIFICATIONSETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_NOTIFICATIONSETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        notificationSetting: payload.data,
      };
    case appTypes.UPDATE_NOTIFICATIONSETTING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

      //update appointment
    case appTypes.UPDATE_TOKENAPPOINTMENT_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_TOKENAPPOINTMENT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        tokenappointment: payload.data,
      };
    case appTypes.UPDATE_TOKENAPPOINTMENT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };

       //update MESSAGE
    case appTypes.UPDATE_MESSAGE_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_MESSAGE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        message: payload.data,
      };
    case appTypes.UPDATE_MESSAGE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };



       //update FOLLOWUP
    case appTypes.UPDATE_FOLLOWUP_REQUEST:
      return {
        ...state,
        isLoading: true,
        error: null,
      };
    case appTypes.UPDATE_FOLLOWUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        followup: payload.data,
      };
    case appTypes.UPDATE_FOLLOWUP_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };


    // case appTypes.UPDATE_TASK_STATUS_REQUEST:
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // case appTypes.UPDATE_TASK_STATUS_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     tasks: state.tasks.map(task =>
    //       task.id === action.payload.id ? action.payload : task
    //     ),
    //   };
    // case appTypes.UPDATE_TASK_STATUS_FAILURE:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.payload,
    //   };

    case appTypes.SET_APP_LOADING:
      return { ...state, appLoading: payload.loading };

    default:
      return state;
  }
};