import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AppGrid from 'src/components/App/AppGrid';
import { Button } from 'src/components/shared';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import PickerWithButtonField from 'src/components/App/DatePicker';
import { useNavigate } from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VisibilityIcon from '@mui/icons-material/Visibility';
import AddTaskIcon from '@mui/icons-material/AddTask';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import { useSelector, useDispatch } from 'react-redux';
import { fetchMedicalRecordsRequest ,deleteMedicalRecordRequest} from 'src/modules/app/store/appActions';
import storage from 'src/utils/storageUtils';
import dayjs from 'dayjs';
import toast from 'src/utils/toast';


function MedicalRecordContainer() {
  const [open, setOpen] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [medicalRecords, setMedicalRecords] = useState([]);
  const [selectedMedicalRecordId, setSelectedMedicalRecordId] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN'); 
      const response = await new Promise(
        (resolve, reject) => dispatch(fetchMedicalRecordsRequest(token, resolve, reject)),
      );
      if (response && response.length) {
        setMedicalRecords(response); 
      }
    };

    fetchData();
  }, [dispatch]);

  const handleActionMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedMedicalRecordId(id);
  };

  const handleDeleteMedicalRecord = async () => {
    try {
      await new Promise((resolve, reject) =>
        dispatch(deleteMedicalRecordRequest(selectedMedicalRecordId, resolve, reject)),
      );
      setMedicalRecords(medicalRecords.filter((medicalrecord) => medicalrecord.id !== selectedMedicalRecordId));
      handleMenuClose();
      toast.success('message Deleted Successfully')
    } catch (error) {
      console.error('Failed to delete medicalrecord:', error);
    }
  };

  const handleMenuClose = () => {
    setOpen(null);
  };

  const addmedicalrecord = () => {
    navigate('/medicalrecord');
  };

  const handleEditMedicalRecord = () => {
    if (selectedMedicalRecordId) {
      navigate(`/medicalrecord/${selectedMedicalRecordId}`);
    } else {
      toast.error('MedicalRecord information is missing');
    }
  };


  const columns = [
    {
      label: 'Sr.No',
      name: 'index',
      width: '30%',
      options: {
        customBodyRenderLite: (dataIndex) => dataIndex + 1,
      },
    },
    {
      label: 'Notes',
      name: 'notes',
    },
    {
      label: 'User Name',
      name: 'username',
    },
    {
      label: 'Patient Name',
      name: 'patient',
    },
    {
      label: 'Date',
      name: 'date',
      options: {
        customBodyRender: (date) => dayjs(date).format('DD MMM YYYY'),
      },
    },
    {
      label: 'Time',
      name: 'time',
      options: {
        customBodyRender: (time) => dayjs(time).format('hh:mm A'),
      },
    },
    {
      label: 'Record Type',
      name: 'recordType',
    },
    {
      label: '',
      name: 'Action',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const medicalrecord = medicalRecords[dataIndex];
          return (
            <>
              <IconButton onClick={(event) => handleActionMenu(event, medicalrecord.id)}>
                <MoreVertIcon />
              </IconButton>
              <Popover
                open={Boolean(open)}
                anchorEl={open}
                onClose={handleMenuClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                  sx: { width: 170 },
                }}
              >
                <MenuItem onClick={handleEditMedicalRecord}>
                  <Stack spacing={1} direction="row">
                    <EditIcon />
                    <span>Edit</span>
                  </Stack>
                </MenuItem>
                <MenuItem onClick={handleDeleteMedicalRecord} sx={{ color: 'error.main' }}>
                  <Stack spacing={1} direction="row">
                    <DeleteIcon />
                    <span>Delete</span>
                  </Stack>
                </MenuItem>
              </Popover>
            </>
          );
        },
      },
    },
  ];

  return (
    <>
      <Box
        sx={{
          marginBottom: 2,
          position: 'relative',
          bottom: '40px',
        }}
      >
        <Grid container spacing={3} sx={{position:'relative',top:'22px'}}>
          <Grid item xs={10} container>
            <Grid item xs={6}>
              <Stack spacing={1} direction="row">
                <Button variant="contained">Today</Button>
                <Button variant="contained">Tomorrow</Button>
                <Button variant="contained">Overmorrow</Button>
                <PickerWithButtonField />
              </Stack>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button onClick={addmedicalrecord} variant="contained">
                New MedicalRecord
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          marginBottom: 2,
          position: 'relative',
          bottom: '20px',
        }}
      >
        <Grid container spacing={3}>
          <Grid item xs={10}>
            <AppGrid
              columns={columns}
              data={medicalRecords}
              selectableRows="none"
              onFilterChange={() => {}}
              appliedFilters={{}}
              bulkActions={[]}
              onBulkAction={() => {}}
              onSort={() => {}}
              onClearFilters={() => {}}
              title=""
              titleProps={{}}
              pagingType="table"
            />
          </Grid>
        </Grid>
      </Box>

    </>
  );
}

export default MedicalRecordContainer;
