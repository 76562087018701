import React, { useState, useEffect, useMemo } from 'react';
import { FormBox } from '../doctor/style';
import * as Yup from 'yup';
import { Grid, Typography } from '@mui/material';
import { Button } from 'src/components/shared';
import InputComponent from 'src/components/shared/Form/Input';
import Select from 'src/components/shared/Form/Select';
import { addAreaRequest,updateAreaRequest, fetchAreaByIdRequest, fetchDoctornameRequest, fetchClinicnameRequest, fetchPatientnameRequest } from '../../store/appActions';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import toast from "src/utils/toast";
import { useSnackbar } from 'notistack';
import { useParams, useNavigate } from 'react-router-dom';

function Area() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors);
    const clinics = useSelector((state) => state.app.clinics);
    const patients = useSelector((state) => state.app.patients);
    const { id } = useParams(); 
    const { enqueueSnackbar } = useSnackbar();
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState({
        area: '',
        user: '',
        clinic: '',
        patient: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await Promise.all([
                    new Promise((resolve, reject) => dispatch(fetchDoctornameRequest(resolve, reject))),
                    new Promise((resolve, reject) => dispatch(fetchClinicnameRequest(resolve, reject))),
                    new Promise((resolve, reject) => dispatch(fetchPatientnameRequest(resolve, reject)))
                ]);
                if (id) {
                    await new Promise((resolve, reject) => {
                        dispatch(fetchAreaByIdRequest(id, (area) => {
                            setInitialValues({
                                area: area.area || '',
                                user: area.user || '',
                                clinic: area.clinic || '',
                                patient: area.patient || '',
                            });
                        }, reject));
                    });
                }
                setIsLoading(false);
            } catch (error) {
                enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id, enqueueSnackbar]);

    const handleSubmit = async (values, { resetForm }) => {
        const area = { ...values };

        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updateAreaRequest(id, area, resolve, reject));
                });
                toast.success('Area updated successfully');
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addAreaRequest(area, resolve, reject));
                });
                toast.success('Area added successfully');
            }

            resetForm();
            navigate('/areas');
        } catch (error) {
            console.log(error);
        }
    };

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({
            label: doctor.name,
            value: doctor.id,
        }))
    ), [doctors]);

    const clinicOptions = useMemo(() => (
        clinics.map((clinic) => ({
            label: clinic.name,
            value: clinic.id,
        }))
    ), [clinics]);

    const patientOptions = useMemo(() => (
        patients.map((patient) => ({
            label: patient.name,
            value: patient.id,
        }))
    ), [patients]);

    return (
        <FormBox sx={{ marginTop: '20px' }}>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={Yup.object().shape({
                    area: Yup.string().required("Please enter the area's name!"),
                    user: Yup.string().required('Please select a user!'),
                    clinic: Yup.string().required('Please select a clinic!'),
                    patient: Yup.string().required('Please select a patient!'),
                })}
                onSubmit={handleSubmit}
            >
                {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                    <form onSubmit={handleSubmit} noValidate>
                        <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                            <Typography variant="h4" color='#393A96' fontWeight='bold'>
                                {id ? 'Edit Area' : 'Add Area'}
                            </Typography>

                            <Grid item>
                                <Typography variant="h5" fontWeight="bold">
                                    Area
                                </Typography>
                                <InputComponent
                                    variant="outlined"
                                    fullWidth
                                    name="area"
                                    value={values.area}
                                    onChange={handleChange}
                                    error={touched.area && Boolean(errors.area)}
                                    helperText={touched.area && errors.area}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='user'
                                    value={values.user}
                                    onChange={(e) => setFieldValue('user', e.target.value)}
                                    options={doctorOptions}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Clinic
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='clinic'
                                    value={values.clinic}
                                    onChange={(e) => setFieldValue('clinic', e.target.value)}
                                    options={clinicOptions}
                                    placeholder="Select a clinic"
                                    error={touched.clinic && Boolean(errors.clinic)}
                                    helperText={touched.clinic && errors.clinic}
                                />
                            </Grid>

                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Patient
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='patient'
                                    value={values.patient}
                                    onChange={(e) => setFieldValue('patient', e.target.value)}
                                    options={patientOptions}
                                    placeholder="Select a patient"
                                    error={touched.patient && Boolean(errors.patient)}
                                    helperText={touched.patient && errors.patient}
                                />
                            </Grid>

                            <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '30px' }}>
                                <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                                    {isLoading ? (id ? 'Updating...' : 'Saving...') : (id ? 'Update Area' : 'Save Area')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </FormBox>
    );
}

export default Area;
