import api from 'src/api';

export const loginUser = (data) => {
  return api('/admin/login', data, 'post');
};

export const addDoctor = (data) => {
  return api('/user', data, 'post');
};

export const addPatient = (data) => {
  return api('/patient', data, 'post');
};

export const addClinic = (data) => {
  return api('/clinic', data, 'post');
};

export const addTokenAppointment = (data) => {
  return api('/tokenappointment', data, 'post');
};

export const addTask = (data) => {
  return api('/task', data, 'post');
};

export const addStaff = (data) => {
  return api('/staff', data, 'post');
};

export const addArea = (data) => {
  return api('/area', data , "post" );
};

export const addTag = (data) => {
  return api('/tag', data , "post" );
}

export const addBilling = (data) => {
  return api('/billing', data , "post" );
}

export const addPermission = (data) => {
  return api('/permissions', data , "post" );
}

export const addTemplate = (data) => {
  return api('/template', data , "post" );
}

export const addTreatment = (data) => {
  return api('/treatment', data , "post" );
}

export const addMedicalRecord = (data) => {
  return api('/medicalrecord', data , "post" );
}

export const addFollowup = (data) => {
  return api('/followup', data , "post" );
}

export const addNotificationSetting = (data) => {
  return api('/doctorsetting', data , "post" );
}

export const addNotification = (data) => {
  return api('/notification', data , "post" );
}

export const addMessage = (data) => {
  return api('/message', data , "post" );
}

export const fetchDoctors = () => {
  return api('admin/users',null, 'get');
};

export const fetchPatients = () => {
  return api('/admin/patients',null, 'get');
};

export const fetchStaffs = () => {
  return api('/admin/staff',null, 'get');
};

export const fetchTasks = () => {
  return api('/admin/tasks',null, 'get');
};

export const fetchClinics = () => {
  return api('/admin/clinics',null, 'get');
};

export const fetchTokenAppointments = () => {
  return api('/admin/tokenappointments',null, 'get');
};

export const fetchTokenAppointmentsByPatient = (patientId) => {
  return api(`/admin/tokenappointment/${patientId}`,null, 'get');
};

export const fetchBillings = () => {
  return api('/admin/billings',null, 'get');
};

export const fetchAreas = () => {
  return api('/admin/areas',null, 'get');
};

export const fetchTags = () => {
  return api('/admin/tags',null, 'get');
};

export const fetchTreatments = () => {
  return api('/admin/treatments',null, 'get');
}

export const fetchTemplates = () => {
  return api('/admin/templates',null, 'get');
}

export const fetchPermissions = () => {
  return api('/admin/permissions',null, 'get')
}

export const fetchMedicalRecords = () => {
  return api('/admin/medicalrecords',null, 'get')
}

export const fetchFollowups = () => {
  return api('/admin/followups',null, 'get')
} 

export const fetchNotificationSettings = () => {
  return api('/admin/doctorsetting',null, 'get')
} 

export const fetchMessages = () => {
  return api('/admin/messages',null, 'get')
} 

export const fetchNotifications = () => {
  return api('/admin/notifications',null, 'get')
} 

export const deleteDoctor = (id) => {
  if (!id) {
    throw new Error('Doctor ID is required to delete a doctor.');
  }
  return api(`/user/${id}`, null, 'delete');
};

export const deletePatient = (id) => {
  if (!id) {
    throw new Error('patient ID is required to delete a patient.');
  }
  return api(`/patient/${id}`, null, 'delete');
};

export const deleteTokenAppointment = (id) => {
  if (!id) {
    throw new Error('appointment ID is required to delete a appointment.');
  }
  return api(`/tokenappointment/${id}`, null, 'delete');
};

export const deleteStaff = (id) => {
  if (!id) {
    throw new Error('staff ID is required to delete a staff.');
  }
  return api(`/staff/${id}`, null, 'delete');
};

export const deleteArea = (id) => {
  if (!id) {
    throw new Error('area ID is required to delete a area.');
  }
  return api(`/area/${id}`, null, 'delete');
};

export const deleteBilling = (id) => {
  if (!id) {
    throw new Error('billing ID is required to delete a billing.');
  }
  return api(`/billing/${id}`, null, 'delete');
};

export const deleteClinic = (id) => {
  if(!id){
    throw new Error ('clinic id is required to delete a clinic');
  }
  return api(`/clinic/${id}`, null ,'delete');
}

export const deleteTag = (id) => {
  if(!id){
    throw new Error ('tag id is required to delete a tag');
  }
  return api(`/tag/${id}`, null ,'delete');
}

export const deleteTreatment = (id) => {
  if(!id){
    throw new Error ('treatment id is required to delete a treatment');
  }
  return api(`/treatment/${id}`, null ,'delete');
}

export const deletePermission = (id) =>{
  if(!id){
    throw new Error ('permission id is required to delete a permission');
  }
  return api(`/permissions/${id}`, null ,'delete');
}

export const deleteTemplate = (id) =>{
  if(!id){
    throw new Error ('template id is required to delete a template');
  }
  return api(`/template/${id}`, null ,'delete');
}

export const deleteMedicalRecord = (id) =>{
  if(!id){
    throw new Error ('medicalrecord id is required to delete a medical record');
  }
  return api(`/medicalrecord/${id}`, null ,'delete');
}

export const deleteFollowup = (id) =>{
  if(!id){
    throw new Error ('followup id is required to delete a followup');
  }
  return api(`/followup/${id}`, null ,'delete');
}

export const deleteNotificationSetting = (id) =>{
  if(!id){
    throw new Error ('notificationsetting id is required to delete a notificationsetting');
  }
  return api(`/doctorsetting/${id}`, null ,'delete');
}

export const deleteNotification = (id) =>{
  if(!id){
    throw new Error ('notification id is required to delete a notification');
  }
  return api(`/notification/${id}`, null ,'delete');
}

export const deleteMessage = (id) =>{
  if(!id){
    throw new Error ('Message id is required to delete a Message');
  }
  return api(`/message/${id}`, null ,'delete');
}

export const updateDoctorById = (doctorId, data) => {
  return api(`/user/${doctorId}`, data, 'put');
};

export const updatePatientById = (patientId, data) => {
  return api(`/patient/${patientId}`, data, 'put');
};

export const updateTokenAppointmentById = (appointmentId, data) => {
  return api(`/tokenappointment/${appointmentId}`, data, 'put');
};

export const updateAreaById = (areaId, data) => {
  return api(`/area/${areaId}`, data, 'put');
};

export const updateClinicById = (clinicId, data) => {
  return api(`/clinic/${clinicId}`, data, 'put');
};

export const updateStaffById = (staffId, data) => {
  return api(`/staff/${staffId}`, data, 'put');
};

export const updateTaskById = (taskId, data) => {
  return api(`/task/${taskId}`, data, 'put');
};


export const updateTagById = (tagId, data) => {
  return api(`/tag/${tagId}`, data, 'put');
};

export const updateBillingById = (billingId, data) => {
  return api(`/billing/${billingId}`, data, 'put');
};

export const updatePermissionById = (permissionId, data) => {
  return api(`/permissions/${permissionId}`, data, 'put');
};

export const updateMedicalRecordById = (medicalrecordId, data) => {
  return api(`/medicalrecord/${medicalrecordId}`, data, 'put');
};

export const updateTemplateById = (templateId, data) => {
  return api(`/template/${templateId}`, data, 'put');
};

export const updateNotificationById = (notificationId, data) => {
  return api(`/notification/${notificationId}`, data, 'put');
};

export const updateNotificationSettingById = (notificationSettingId, data) => {
  return api(`/doctorsetting/${notificationSettingId}`, data, 'put');
};

export const updateMessageById = (messageId, data) => {
  return api(`/message/${messageId}`, data, 'put');
};

export const updateFollowupById = (followupId, data) => {
  return api(`/followup/${followupId}`, data, 'put');
};


//fetch patientById 
export const fetchPatientById = (id) => {
  if (!id) {
    throw new Error('Patient ID is required to fetch patient details.');
  }
  return api(`/patient/${id}`, null, 'get');
};

export const fetchDoctorById = (id) => {
  if (!id) {
    throw new Error('Doctor ID is required to fetch doctor details.');
  }
  return api(`/user/${id}`, null, 'get');
};

export const fetchTokenAppointmentById = (id) => {
  if (!id) {
    throw new Error('appointment ID is required to fetch appointment details.');
  }
  return api(`/tokenappointment/${id}`, null, 'get');
};

export const fetchAreaById = (id) => {
  if (!id) {
    throw new Error('Area ID is required to fetch area details.');
  }
  return api(`/area/${id}`, null, 'get');
};

export const fetchClinicById = (id) => {
  if (!id) {
    throw new Error('clinic ID is required to fetch clinic details.');
  }
  return api(`/clinic/${id}`, null, 'get');
};

export const fetchStaffById = (id) => {
  if (!id) {
    throw new Error('staff ID is required to fetch staff details.');
  }
  return api(`/staff/${id}`, null, 'get');
};

export const fetchTaskById = (id) => {
  if (!id) {
    throw new Error('task ID is required to fetch task details.');
  }
  return api(`/task/${id}`, null, 'get');
};

export const fetchTagById = (id) => {
  if (!id) {
    throw new Error('tag ID is required to fetch tag details.');
  }
  return api(`/tag/${id}`, null, 'get');
};

export const fetchBillingById = (id) => {
  if (!id) {
    throw new Error('billing ID is required to fetch billing details.');
  }
  return api(`/billing/${id}`, null, 'get');
};

export const fetchPermissionById = (id) => {
  if (!id) {
    throw new Error('Permission ID is required to fetch Permission details.');
  }
  return api(`/permission/${id}`, null, 'get');
};

export const fetchMedicalRecordById = (id) => {
  if (!id) {
    throw new Error('medicalrecord ID is required to fetch medicalrecord details.');
  }
  return api(`/medicalrecord/${id}`, null, 'get');
};

export const fetchTemplateById = (id) => {
  if (!id) {
    throw new Error('template ID is required to fetch template details.');
  }
  return api(`/template/${id}`, null, 'get');
};

export const fetchNotificationById = (id) => {
  if (!id) {
    throw new Error('Notification ID is required to fetch Notification details.');
  }
  return api(`/notification/${id}`, null, 'get');
};

export const fetchNotificationSettingById = (id) => {
  if (!id) {
    throw new Error('Notification ID is required to fetch Notification details.');
  }
  return api(`/doctorsetting/${id}`, null, 'get');
};

export const fetchMessageById = (id) => {
  if (!id) {
    throw new Error('Message ID is required to fetch Message details.');
  }
  return api(`/message/${id}`, null, 'get');
};

export const fetchFollowupById = (id) => {
  if (!id) {
    throw new Error('Followup ID is required to fetch Followup details.');
  }
  return api(`/followup/${id}`, null, 'get');
};


export const updateTaskStatus = (id, data) => {
  if (!id) {
    throw new Error('Task Status ID is required to fetch .');
  }
  return api(`/taskstatus/${id}`, data, 'get');
};

