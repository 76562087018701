import React, { useState, useEffect } from 'react';
import {
  Grid, Stack, Box, Table, TableBody, TableCell, TableContainer, TableHead,
  TableRow, Paper, Typography, Drawer, TextField, IconButton, Tooltip, Divider, Button
} from "@mui/material";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import FullScreenIcon from '@mui/icons-material/Fullscreen';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { fetchTasksRequest } from 'src/modules/app/store/appActions';
import storage from 'src/utils/storageUtils';

function TasksContainer() {
  const navigate = useNavigate();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const token = storage.get('TOKEN');
      const response = await new Promise((resolve, reject) =>
        dispatch(fetchTasksRequest(token, resolve, reject)),
      );
      if (response && response.length) {
        setTasks(response);
      }
    };

    fetchData();
  }, []);

  const AddTask = () => {
    navigate('/task');
  };

  // const handleEditTask = () => {
  //   if (selectedTaskId) {
  //     navigate(`/task/${selectedTaskId}`);
  //   } else {
  //     toast.error('Task information is missing');
  //   }
  // };

  const handleRowClick = (task) => {
    setSelectedTask(task);
    setOpenDrawer(true);
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setSelectedTask(null);
  };

  const handleMarkComplete = () => {
    if (!selectedTask) return;

    const updatedTasks = tasks.map((task) =>
      task.id === selectedTask.id ? { ...task, status: 'Complete' } : task
    );
    handleDrawerClose();
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  if (!tasks || !Array.isArray(tasks)) {
    return <Typography variant="body1">Loading tasks...</Typography>;
  }

  return (
    <>
      <Box sx={{ marginBottom: 2, position: 'relative', bottom: '30px' }}>
        <Grid container spacing={3} sx={{position:'relative',top:'22px'}}>
          <Grid item xs={10} container>
            <Grid item xs={6}>
              <Stack spacing={1} direction="row">
                <Button variant="contained">Today</Button>
                <Button variant="outlined">Tomorrow</Button>
              </Stack>
            </Grid>
            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button variant="contained" onClick={AddTask}>Add New Task</Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Grid container spacing={2} sx={{ width: '85%', position: 'relative', bottom: '20px' }}>
        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="task table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ position: "relative", textAlign: 'center' }}>Task Name</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell>Priority</TableCell>
                  <TableCell>Assigned To</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks.map((task, index) => (
                  <TableRow key={index} onClick={() => handleRowClick(task)}>
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <CheckCircleIcon color={task.status === 'Complete' ? "success" : "error"} />
                        <Typography variant="body1" ml={2}>{task.name}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell>{task.dueDate}</TableCell>
                    <TableCell>{task.priority}</TableCell>
                    <TableCell>{task.assigned}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleDrawerClose}
        sx={{
          '& .MuiDrawer-paper': {
            width: isFullScreen ? '100%' : '400px',
            height: isFullScreen ? '100%' : 'auto',
            height: '100%'
          },
        }}
      >
        {selectedTask && (
          <Box p={2} display="flex" flexDirection="column" height="100%">
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="h4" fontWeight="bold">
                {selectedTask.name}
              </Typography>
              <Box display="flex" alignItems="center">
                <Tooltip title="Close">
                  <IconButton onClick={handleDrawerClose}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Full Screen">
                  <IconButton onClick={toggleFullScreen}>
                    <FullScreenIcon />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Status">
                  <IconButton>
                    <ThumbUpIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Divider />
            <Box>
              <Typography variant="body1" gutterBottom>
                Assigned To: {selectedTask.assigned}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" gutterBottom>
                Due Date: {selectedTask.dueDate}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" gutterBottom>
                Priority: {selectedTask.priority}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" gutterBottom>
                Status: {selectedTask.status}
              </Typography>
            </Box>
            <Box>
              <Typography variant="body1" gutterBottom>
                Description: {selectedTask.description}
              </Typography>
            </Box>
            <Box my={2}>
              <Typography variant="body1" gutterBottom>
                Comments:
              </Typography>
              <TextField fullWidth variant="outlined" multiline rows={4} />
            </Box>
            <Box my={2} display="flex" justifyContent="space-between">
              <Tooltip title="Save the Comments">
                <Button variant="contained" color="primary">
                  Save
                </Button>
              </Tooltip>
              <Tooltip title="Task is Complete Click it">
                <Button variant="outlined" onClick={handleMarkComplete}>
                  <CheckIcon />
                  Mark Complete
                </Button>
              </Tooltip>
            </Box>
          </Box>
        )}
      </Drawer>
    </>
  );
}

export default TasksContainer;