import React, { useState, useEffect, useMemo } from 'react';
import { FormBox } from "../doctor/style";
import { Grid, Typography, Stack, Tooltip, FormControlLabel, Checkbox } from '@mui/material';
import { Button } from "src/components/shared";
import Select from 'src/components/shared/Form/Select';
import Datepicker from 'src/components/shared/Form/Datepicker';
import NumberInput from 'src/components/shared/Form/Number';
import AppTimePicker from 'src/components/shared/Form/Timepicker';
import { fetchDoctornameRequest, fetchPatientnameRequest, fetchTokenAppointmentByPatientIdRequest, addTreatmentRequest,fetchClinicnameRequest } from '../../store/appActions'; 
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import dayjs from 'dayjs';
import toast from "src/utils/toast";

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  total: Yup.number().required('Total is required').positive('Total must be positive').integer('Total must be an integer'),
  remaining: Yup.number().required('Remaining is required').positive('Remaining must be positive').integer('Remaining must be an integer'),
  patient: Yup.string().required('Patient is required'),
  user: Yup.string().required('User is required'),
  clinic: Yup.string().required('Clinic is required'),
  tokenAppointment: Yup.string().required('Please select the token appointment!'),
  treatmentType: Yup.string().required('Treatment Type is required'),
  days: Yup.array().when('treatmentType', {
    is: 'visiting',
    then: schema => schema.min(1, 'At least one day must be selected').required('Days are required for visiting treatment'),
    otherwise: schema => schema.nullable(),
  }),
  time: Yup.string().when('treatmentType', {
    is: 'visiting',
    then: schema => schema.required('Time is required for visiting treatment'),
    otherwise: schema => schema.nullable(),
  }),
  dateTimes: Yup.array().when('treatmentType', {
    is: 'sitting',
    then: schema => schema.of(
      Yup.object().shape({
        date: Yup.date().required('Date is required'),
        time: Yup.string().required('Time is required'),
      })
    ).required('Date and Time are required for sitting treatment'),
    otherwise: schema => schema.nullable(),
  }),
});

  

function Treatment() {
  const [sittings, setSittings] = useState(0);
  const [dateTimes, setDateTimes] = useState([]);
  const [treatmentType, setTreatmentType] = useState('visiting');
  const [selectedPatient, setSelectedPatient] = useState('');
  const [tokenAppointments, setTokenAppointments] = useState([]);
  const dispatch = useDispatch();
  const doctors = useSelector((state) => state.app.doctors);
  const clinics = useSelector((state) => state.app.clinics || []);
  const patients = useSelector((state) => state.app.patients);
  const [isLoading, setIsLoading] = useState(true);

  
  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([
          dispatch(fetchDoctornameRequest()),
          dispatch(fetchClinicnameRequest()),
          dispatch(fetchPatientnameRequest()),
        ]);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [dispatch]);

  const handleFetchTokenAppointments = (patientId) => {
    dispatch(fetchTokenAppointmentByPatientIdRequest(patientId, (data) => {
      setTokenAppointments(data);
    }, (error) => {
      console.error('Error:', error);
    }));
  };

  const handlePatientChange = (e) => {
    const patientId = e.target.value;
    setSelectedPatient(patientId);
    handleFetchTokenAppointments(patientId);
  };

  const handleSittingsChange = (event, setFieldValue) => {
    const value = parseInt(event.target.value, 10);
    setSittings(value);
    setDateTimes(Array(value).fill({ date: null, time: null }));
    setFieldValue('total', value);
    setFieldValue('dateTimes', Array(value).fill({ date: null, time: null }));
  };

  const handleDateChange = (index, date, setFieldValue) => {
    const newDateTimes = [...dateTimes];
    newDateTimes[index].date = date;
    setDateTimes(newDateTimes);
    setFieldValue('dateTimes', newDateTimes);
  };

  const handleTimeChange = (index, time, setFieldValue) => {
    const newDateTimes = [...dateTimes];
    newDateTimes[index].time = time;
    setDateTimes(newDateTimes);
    setFieldValue('dateTimes', newDateTimes);
  };

  const doctorOptions = useMemo(() => (
    doctors.map((doctor) => ({
      label: doctor.name,
      value: doctor.id,
    }))
  ), [doctors]);

  const patientOptions = useMemo(() => (
    patients.map((patient) => ({
      label: patient.name,
      value: patient.id,
    }))
  ), [patients]);

  const clinicOptions = useMemo(() => (
    clinics.map((clinic) => ({
        label: clinic.name,
        value: clinic.id,
    }))
), [clinics]);

  const tokenAppointmentOptions = useMemo(() => (
    tokenAppointments.map((appointment) => ({
      label: appointment.id,
      value: appointment.id,
    }))
  ), [tokenAppointments]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      await dispatch(addTreatmentRequest(values));
      toast.success('Treatment Added Successfully');
      resetForm();
    } catch (error) {
      toast.error('Error adding treatment');
    }
  };

  return (
    <FormBox marginTop='20px'>
      <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
        <Typography variant="h4" color='#393A96' fontWeight='bold' sx={{ marginLeft: '2%' }}>
          Treatment
        </Typography>

        <Formik
          initialValues={{
            name: '',
            total: '',
            remaining: '',
            patient: '',
            user: '',
            clinic: '',
            tokenAppointment: '',
            treatmentType: 'visiting',
            days: [],
            time: '',
            dateTimes: [],
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, handleChange, setFieldValue, touched, errors,handleSubmit,isValid }) => (
            <Form onSubmit={handleSubmit}>
            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    User
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='user'
                                    value={values.user}
                                    onChange={(e) => setFieldValue('user', e.target.value)}
                                    options={doctorOptions}
                                    placeholder="Select a doctor"
                                    error={touched.user && Boolean(errors.user)}
                                    helperText={touched.user && errors.user}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Clinic
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='clinic'
                                    value={values.clinic}
                                    onChange={(e) => setFieldValue('clinic', e.target.value)}
                                    options={clinicOptions}
                                    placeholder="Select a clinic"
                                    error={touched.clinic && Boolean(errors.clinic)}
                                    helperText={touched.clinic && errors.clinic}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Patient
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='patient'
                                    value={values.patient}
                                    onChange={(e) => {
                                      handlePatientChange(e); // Call your function here
                                      setFieldValue('patient', e.target.value); // Still setting the value for Formik
                                    }}                                    options={patientOptions}
                                    placeholder="Select a patient"
                                    error={touched.patient && Boolean(errors.patient)}
                                    helperText={touched.patient && errors.patient}
                                />
                            </Grid>
                            <Grid item>
                                <Typography variant='h5' fontWeight='bold'>
                                    Appointment
                                </Typography>
                                <Select
                                    variant='outlined'
                                    fullWidth
                                    name='tokenAppointment'
                                    value={values.tokenAppointment}
                                    onChange={handleChange}
                                    options={tokenAppointmentOptions}
                                    placeholder="Select a token appointment"
                                    error={touched.tokenAppointment && Boolean(errors.tokenAppointment)}
                                    helperText={touched.tokenAppointment && errors.tokenAppointment}
                                />
                            </Grid>
              <Grid item>
                <Typography variant="h5" fontWeight="bold">
                  Treatment Type
                </Typography>
                <Tooltip title="Select the type of treatment (Visiting or Sitting)">
                  <Select
                    fullWidth
                    name="treatmentType"
                    value={values.treatmentType}
                    options={[
                      { label: 'Sitting', value: 'sitting' },
                      { label: 'Visit', value: 'visiting' },
                    ]}
                    onChange={(e) => {
                      handleChange(e);
                      setTreatmentType(e.target.value);
                      setFieldValue('treatmentType', e.target.value);
                    }}
                    variant="outlined"
                  />
                </Tooltip>
              </Grid>

              {values.treatmentType === 'visiting' && (
                <>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Total Visit
                    </Typography>
                    <Tooltip title="Enter the total number of visits required">
                      <NumberInput
                        variant="outlined"
                        fullWidth
                        name="treatmentType"
                        value={values.treatmentType}
                        onChange={(e) => {
                          handleChange(e);
                          handleSittingsChange(e, setFieldValue);
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      When Visit
                    </Typography>
                    <Tooltip title="Select the days of the week for visits">
                      <Grid item container spacing={1} sx={{ position: 'relative', bottom: '30px', left: '12%' }}>
                        {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day, index) => (
                          <Grid item key={index}>
                            <FormControlLabel
                              control={<Checkbox
                                checked={values.days.includes(day)}
                                onChange={() => {
                                  const updatedDays = values.days.includes(day)
                                    ? values.days.filter(d => d !== day)
                                    : [...values.days, day];
                                  setFieldValue('days', updatedDays);
                                }}
                              />}
                              label={day}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Tooltip>
                  </Grid>
                  <Grid item sx={{ position: 'relative', bottom: 50 }}>
                    <Typography variant="h5" fontWeight="bold" sx={{ position: 'relative', top: 15 }}>
                      Visit Time
                    </Typography>
                    <Tooltip title="Select the time for visits">
                      <AppTimePicker
                        fullWidth
                        name="time"
                        value={values.time}
                        onChange={(time) => setFieldValue('time', time)}
                      />
                    </Tooltip>
                  </Grid>
                </>
              )}

              {values.treatmentType === 'sitting' && (
                <>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Total Sitting
                    </Typography>
                    <Tooltip title="Enter the total number of sittings required">
                      <NumberInput
                        variant="outlined"
                        fullWidth
                        name="total"
                        value={values.total}
                        onChange={(e) => {
                          handleChange(e);
                          handleSittingsChange(e, setFieldValue);
                        }}
                      />
                    </Tooltip>
                  </Grid>
                  <Grid item>
                    <Typography variant="h5" fontWeight="bold">
                      Select for Sitting
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Stack direction="row" spacing={11}>
                      <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                        Select Date
                      </Typography>
                      <Typography variant="h5" fontWeight="bold" sx={{ width: '45%' }}>
                        Select Time
                      </Typography>
                    </Stack>
                    {dateTimes.map((_, index) => (
                      <Stack direction="row" key={index}>
                        <Datepicker
                          sx={{ width: '45%' }}
                          value={dateTimes[index]?.date || null}
                          onChange={(date) => handleDateChange(index, date, setFieldValue)}
                        />
                        <AppTimePicker
                          sx={{ width: '45%' }}
                          value={dateTimes[index]?.time || null}
                          onChange={(time) => handleTimeChange(index, time, setFieldValue)}
                        />
                      </Stack>
                    ))}
                  </Grid>
                </>
              )}

              <Grid item container justifyContent="center" sx={{ marginTop: '30px' }}>
                 <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                                    Save Treatment
                                </Button>
              </Grid>

            </Form>
          )}
        </Formik>
      </Grid>
    </FormBox>
  );
}

export default Treatment;
