import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import {
    Box,
    Typography,
    Grid,
    Tooltip,
} from '@mui/material';
import { Button } from 'src/components/shared';
import { Formik } from 'formik';
import { addNotificationSettingRequest, updateNotificationSettingRequest, fetchNotificationSettingByIdRequest, fetchDoctornameRequest } from '../../store/appActions';
import { FormBox } from '../doctor/style';
import { useSnackbar } from 'notistack';
import toast from "src/utils/toast";
import Select from 'src/components/shared/Form/Select';
import { useParams, useNavigate } from 'react-router-dom';

function NotificationSetting() {
    const dispatch = useDispatch();
    const doctors = useSelector((state) => state.app.doctors);
    const { enqueueSnackbar } = useSnackbar();
    const { id } = useParams(); // Get the ID from the URL params
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(true);

    const [initialValues, setInitialValues] = useState({
        pushNotification: '',
        webNotification: '',
        whatsappNotification: '',
        emailNotification: '',
        smsNotification: '',
        user: '',
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                await new Promise((resolve, reject) => {
                    dispatch(fetchDoctornameRequest(resolve, reject));
                });

                if (id) {
                    await new Promise((resolve, reject) => {
                        dispatch(fetchNotificationSettingByIdRequest(id, (notificationSetting) => {
                            console.log(notificationSetting); 
                            setInitialValues({
                                pushNotification: notificationSetting.pushNotification ?? '',
                                webNotification: notificationSetting.webNotification ?? '',
                                whatsappNotification: notificationSetting.whatsappNotification ?? '',
                                emailNotification: notificationSetting.emailNotification ?? '',
                                smsNotification: notificationSetting.smsNotification ?? '',
                                user: notificationSetting.user ?? '',
                            });
                        }, reject));
                    });
                    
                }

                setIsLoading(false);
            } catch (error) {
                console.log(error);
                enqueueSnackbar(error.message || 'Failed to load data', { variant: 'error' });
                setIsLoading(false);
            }
        };

        fetchData();
    }, [dispatch, id, enqueueSnackbar]);

    const handleSubmit = async (values, { resetForm }) => {
        const notificationData = { ...values };

        try {
            if (id) {
                await new Promise((resolve, reject) => {
                    dispatch(updateNotificationSettingRequest(id, notificationData, resolve, reject));
                });
                toast.success('Notification Settings updated successfully');
            } else {
                await new Promise((resolve, reject) => {
                    dispatch(addNotificationSettingRequest(notificationData, resolve, reject));
                });
                toast.success('Notification Settings added successfully');
            }

            resetForm();
            navigate('/notificationSettings'); 
        } catch (error) {
            console.log(error);
        }
    };

    const doctorOptions = useMemo(() => (
        doctors.map((doctor) => ({
            label: doctor.name,
            value: doctor.id,
        }))
    ), [doctors]);

    const booleanOptions = [
        { label: 'Yes', value: true },
        { label: 'No', value: false },
    ];

    return (
        <FormBox>
            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={Yup.object().shape({
                        pushNotification: Yup.boolean().required("Please select push notification setting!"),
                        webNotification: Yup.boolean().required("Please select web notification setting!"),
                        whatsappNotification: Yup.boolean().required("Please select WhatsApp notification setting!"),
                        emailNotification: Yup.boolean().required("Please select email notification setting!"),
                        smsNotification: Yup.boolean().required("Please select SMS notification setting!"),
                        user: Yup.string().required('Please select the user!'),
                    })}
                    onSubmit={handleSubmit}
                >
                    {({ values, handleChange, handleSubmit, touched, errors, isValid, setFieldValue }) => (
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container direction="column" spacing={2} sx={{ padding: '30px' }}>
                                <Typography variant="h4" color="#393A96" fontWeight="bold" sx={{ marginLeft: '2%' }}>
                                    {id ? 'Edit Notification Settings' : 'Add Notification Settings'}
                                </Typography>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">
                                        Push Notification
                                    </Typography>
                                    <Tooltip title="Select push notification setting">
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            name="pushNotification"
                                            value={values.pushNotification}
                                            onChange={(e) => setFieldValue('pushNotification', e.target.value)}
                                            options={booleanOptions}
                                            placeholder="Select Push Notification"
                                            error={touched.pushNotification && Boolean(errors.pushNotification)}
                                            helperText={touched.pushNotification && errors.pushNotification}
                                        />
                                    </Tooltip>
                                </Grid>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">
                                        Web Notification
                                    </Typography>
                                    <Tooltip title="Select web notification setting">
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            name="webNotification"
                                            value={values.webNotification}
                                            onChange={(e) => setFieldValue('webNotification', e.target.value)}
                                            options={booleanOptions}
                                            placeholder="Select Web Notification"
                                            error={touched.webNotification && Boolean(errors.webNotification)}
                                            helperText={touched.webNotification && errors.webNotification}
                                        />
                                    </Tooltip>
                                </Grid>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">
                                        WhatsApp Notification
                                    </Typography>
                                    <Tooltip title="Select WhatsApp notification setting">
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            name="whatsappNotification"
                                            value={values.whatsappNotification}
                                            onChange={(e) => setFieldValue('whatsappNotification', e.target.value)}
                                            options={booleanOptions}
                                            placeholder="Select WhatsApp Notification"
                                            error={touched.whatsappNotification && Boolean(errors.whatsappNotification)}
                                            helperText={touched.whatsappNotification && errors.whatsappNotification}
                                        />
                                    </Tooltip>
                                </Grid>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">
                                        Email Notification
                                    </Typography>
                                    <Tooltip title="Select email notification setting">
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            name="emailNotification"
                                            value={values.emailNotification}
                                            onChange={(e) => setFieldValue('emailNotification', e.target.value)}
                                            options={booleanOptions}
                                            placeholder="Select Email Notification"
                                            error={touched.emailNotification && Boolean(errors.emailNotification)}
                                            helperText={touched.emailNotification && errors.emailNotification}
                                        />
                                    </Tooltip>
                                </Grid>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">
                                        SMS Notification
                                    </Typography>
                                    <Tooltip title="Select SMS notification setting">
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            name="smsNotification"
                                            value={values.smsNotification}
                                            onChange={(e) => setFieldValue('smsNotification', e.target.value)}
                                            options={booleanOptions}
                                            placeholder="Select SMS Notification"
                                            error={touched.smsNotification && Boolean(errors.smsNotification)}
                                            helperText={touched.smsNotification && errors.smsNotification}
                                        />
                                    </Tooltip>
                                </Grid>

                                <Grid item>
                                    <Typography variant="h5" fontWeight="bold">
                                        User
                                    </Typography>
                                    <Tooltip title="Select a user">
                                        <Select
                                            variant="outlined"
                                            fullWidth
                                            name="user"
                                            value={values.user}
                                            onChange={(e) => setFieldValue('user', e.target.value)}
                                            options={doctorOptions}
                                            placeholder="Select a doctor"
                                            error={touched.user && Boolean(errors.user)}
                                            helperText={touched.user && errors.user}
                                        />
                                    </Tooltip>
                                </Grid>

                                <Grid container spacing={2} justifyContent="center" sx={{ marginTop: '30px' }}>
                                    <Tooltip title={id ? "Update the notification settings" : "Add the notification settings"} arrow>
                                        <Button type="submit" variant="contained" sx={{ textAlign: 'center' }} disabled={!isValid}>
                                            {id ? 'Update' : 'Save'}
                                        </Button>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </Grid>
        </FormBox>
    );
}

export default NotificationSetting;
