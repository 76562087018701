import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { palette, } from "src/config/theme";
import { Typography } from "@mui/material";

export const FormBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '86%',
})

export const ClinicBox = styled(Box)({
    backgroundColor: palette.white,
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
    width: '86%',
    borderRadius: '16px',
    position: 'relative',
    bottom:'40px',right:'2.5%'
    
})

export const ProfileBackBox =styled(Box)({
    background: '#393A96',
    padding: '10px',
    borderRadius: '10px',
    width: '750px',
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: "relative",
    right: '10px',
    bottom: '10px'
}) 